import {neverland as $, render, html, useState, useEffect} from 'neverland';
import Sortable from 'sortablejs';

import TextSubField from './Collection_Doc_TextSubField.js';
import SelectSubField from './Collection_Doc_SelectSubField.js';
import SequenceSubField from './Collection_Doc_SequenceSubField.js';

function randomString() {
	return Math.random().toString().substr(2, 10);
}

const SequenceField = $(function(f, fields, getDefault, doc, updateDoc, updateDocByFunction, collection) {

	// console.log(doc);

	let sbflds = Object.keys(fields[f].fields),
		subfields = fields[f].fields,
		UIgrps = [],
		UIgroups = {};

	if (fields[f].hasOwnProperty('UIgroups')) {
		UIgrps = Object.keys(fields[f].UIgroups);
		UIgroups = fields[f].UIgroups;
	}

	let [sequenceUpdateId, setSequenceUpdateId] = useState(randomString());

	function getDefaultElement() {
	// Generate a template element with default values for each subfield.

		let template = {};
		for (let sf of sbflds) {
			template[sf] = getDefault(sf, subfields);
			if (subfields[sf].isReference) {
				template['collection'] = '';
			}
		}
		template['continuityId'] = randomString();
		return template;
	}

	function orderUpdateFunction(fromIndex, toIndex) {

		setGroupsAreOpen(prevStates => {
			let state = [...prevStates];
			state.splice(toIndex, 0, state.splice(fromIndex, 1)[0]);
			return state;
		})

		return (prevDoc) => {
			let doc = {...prevDoc},
				seq = [...doc.curr[f]];
			seq.splice(toIndex, 0, seq.splice(fromIndex, 1)[0]);
			doc.curr[f] = seq;
			// console.log(doc.curr[f]);
			return doc;
		}
	}

	let [sortable, setSortable] = useState(null);

	// Manage sortablejs sequence fields.
	useEffect(() => {
		if (sortable === null) {
			setSortable(
				Sortable.create(
					document.querySelector('#sortable-field'),	
					{
						draggable: '.item',
						handle: '.handle',
						animation: 150,
						direction: 'vertical',
						// disabled: true,
						onEnd: function(evt) {

							// Undo sortablejs reordering of elements, so that it is instead handled by neverland state.

							let element = evt.item,
								parent = element.parentNode,
								elements = parent.children,
								old_idx = evt.oldIndex,
								new_idx = evt.newIndex;

							if (old_idx > new_idx) {
								parent.insertBefore(element, elements[old_idx].nextSibling);
							} else {
								parent.insertBefore(element, elements[old_idx])
							}

							// Update neverland state.

							updateDocByFunction(orderUpdateFunction(old_idx - 1, new_idx - 1));
								// Subtract 1 to get the index without the first '.insert' element.
						}
					}
				)
			);
		}

	}, [doc.prev.id, collection.docPaneID])

	function insertAtIndex(idx) {

		return () => {			
			setGroupsAreOpen(prevStates => {
				let state = [...prevStates];
				state.splice(idx, 0, getGroupOpenTemplate());
				return state;
			})

			setSequenceUpdateId(randomString());
			updateDocByFunction(prevDoc => {
				let doc = {...prevDoc},
				seq = [...doc.curr[f]];
				seq.splice(idx, 0, getDefaultElement());
				doc.curr[f] = seq;
				// console.log(doc.curr[f]);
				return doc;	
			})
		}
	}

	function deleteByIndex(idx) {


		return () => {
			setSequenceUpdateId(randomString());
			updateDocByFunction(prevDoc => {
				let doc = {...prevDoc},
				seq = [...doc.curr[f]];
				seq.splice(idx, 1);
				doc.curr[f] = seq;
				// console.log(doc.curr[f]);
				return doc;	
			})
			
			setGroupsAreOpen(prevStates => {
				let state = [...prevStates];
				state.splice(idx, 1);
				return state;
			})
		}
	}

	// Keep track of which groups are open and closed.
	function getGroupOpenTemplate() {
		let templateState = {};
		for (let g of UIgrps) {
			templateState[g] = false;
		}
		return templateState;
	}
	function initGroupsAreOpen() {
		
		let states = [],
			templateState = getGroupOpenTemplate();

		for (let k = 0; k < doc.curr[f].length; k++) {
			states.push({...templateState});
		}

		return states;

	}
	let [groupsAreOpen, setGroupsAreOpen] = useState(initGroupsAreOpen);
	useEffect(() => {
		setGroupsAreOpen(initGroupsAreOpen);
	}, [doc.prev.id, collection.docPaneID]);
	function toggleGroup(idx, g) {
		return () => {
			setGroupsAreOpen(prevStates => {
				let state = [...prevStates],
					groups = Object.keys(state[idx]);
				for (let group of groups) {
					if (g == group) {
						state[idx][group] = !state[idx][group];
					} else {
						state[idx][group] = false;
					}
				}
				return state;
			})
		}
	}

	if (groupsAreOpen.length != doc.curr[f].length) {
		groupsAreOpen = initGroupsAreOpen(); // <-- Realise that this is not the done thing but it is the nicest way I could think of to prevent an error.
		setGroupsAreOpen(initGroupsAreOpen());
	}

	function SubField(sf, idx, continuityId) {
		switch(subfields[sf].type) {
			case 'text':
				return html`${TextSubField(f, fields, idx, sf, doc, updateDocByFunction)}`;
				break;
			case 'select':
				return html`${SelectSubField(f, fields, idx, sf, doc, updateDocByFunction, collection, sequenceUpdateId, continuityId)}`;
				break;
			case 'sequence':
				return html`${SequenceSubField(f, fields, idx, sf, doc, updateDocByFunction, getDefault, collection, sequenceUpdateId, setSequenceUpdateId)}`;
				break;
			default:
				break;
		}
	}

	function getFieldWidths() {
		let ws = '';
		for (let sf of sbflds) {
			if (!subfields[sf].UIgroup) {
				ws = ws + `${subfields[sf].w}% `
			}
		}
		return ws;
	}

	function noUIgroup(sf) {
		return !subfields[sf].hasOwnProperty('UIgroup');
	}

	function importOptionsFromText() {
		
		let optionString = prompt("Enter the response options in Dexterous format."),
			options;

		function isInteger(value) {
			return /^\d+$/.test(value);
		}

		options = optionString.split(',').map(d => {
			let regex = /\[(.*)\]/gm,
				value = regex.exec(d),
				label;

			if (value === null) {
				value = '';
				label = d;
			} else {
				value = value[1];
				label = d.replace(regex, '');
			}

			if (isInteger(value)) {
				value = Number(value);
			}

			return {
				value,
				label,
				continuityId: randomString()
			};
		});

		updateDoc('options', options);

	}

	function Element(d, idx) {
		return html`
			<div class="item">
				<div class="item-content">
					
					<div class="content" style="grid-template-columns: ${getFieldWidths()};">
						${sbflds.filter(noUIgroup).map(sf => html`
							<div class="subfield-box subfield-box-${sf}">
								${SubField(sf, idx, d.continuityId)}
							</div>`)}
					</div>
					
					<div class="buttons">

						${UIgrps.map(g => html`
							<div
								class="button ${g} ${groupsAreOpen[idx][g] ? 'active' : ''}"
								onclick="${toggleGroup(idx, g)}">
									${ {html: UIgroups[g].icon} }</div>
							`)}
						
						<div
							class="button delete"
							onclick="${deleteByIndex(idx)}">&#215;</div>

					</div>
					
					<div class="handle"></div>

					${UIgrps.map(g => html`
						<div
							class="group ${g} ${groupsAreOpen[idx][g] ? '' : 'hide'}">
							${sbflds.filter(sf => subfields[sf].UIgroup == g).map(sf => html`
								<div
									class="subfield-box subfield-box-${sf}"
									style="grid-column: auto / span ${subfields[sf].w_in_ventiles}">
									${SubField(sf, idx, d.continuityId)}
								</div>`)}
						</div>`)}

				</div>
				<div
					class="insert"
					onclick="${insertAtIndex(idx + 1)}">+</div>
			</div>
		`;
	}

	return html`
		<div id="sortable-field" class="sortable">
			<div
				class="insert"
				onclick="${insertAtIndex(0)}">+</div>
			${doc.curr[f].map((d, idx) => Element(d, idx))}
		</div>
		<label>
			${fields[f].title} 
			${f == 'options' ? html`(<button class="inline" onclick="${importOptionsFromText}">Import from text</button>)` : ''}
		</label>
	`;

});

export default SequenceField;