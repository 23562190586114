import {neverland as $, render, html, useState} from 'neverland';

const ResetSuccessPage = $(function(setAuth) {
	
	function toLogin() {
		setAuth(prevAuth => {
			let auth = {...prevAuth};
			auth.publicPage = null;
			return auth;
		})
	}

	return html`
		<div class='login-fields'>

			<p>Password reset successfully!</p>

			<button id="reset-button" onclick="${toLogin}">Return to log in</button>

		</div>
	`;

});

export default ResetSuccessPage;