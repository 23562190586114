import {neverland as $, render, html, useState, useEffect} from 'neverland';


const Collection_Archive = $(function(auth, collection, setCollection) {
	
	let { archiveID, allDocs } = collection;
	let doc = collection.allDocs.filter(d => d.id == archiveID)[0];
	
	function cancel(ev) {
		setCollection(prevCollection => {
			let collection = {...prevCollection};
			collection.archiveID = null;
			return collection;
		})
	}

	async function toggleArchiveStatus(ev) {

		const result = await collection.mongo.updateOne(
			{ _id: doc._id },
			{ $set: { archived: !Boolean(doc.archived) } }
		);
		
		setCollection(prevCollection => {
			let collection = {...prevCollection};
			collection.allDocs.forEach(d => {
				if (d.id == archiveID) {
					d.archived = !Boolean(doc.archived)
				}
			})
			collection.archiveID = null;
			return collection;
		})
	}

	return html`
		<div class="shades">

			<div class="modal">
				<p>Are you sure you want to ${doc.archived ? 'restore' : 'archive'} this ${collection.nounSingular}?</p>

				<button class="muted" onclick="${cancel}">No, cancel</button>
				<button class="red" onclick="${toggleArchiveStatus}">Yes, ${doc.archived ? 'restore' : 'archive'}</button>
			</div>

		</div>
	`;

});

export default Collection_Archive;