import {neverland as $, render, html, useState, useEffect} from 'neverland';

function isInteger(value) {
	return /^\d+$/.test(value);
}

const ColourField = $(function(f, fields, doc, updateDoc) {
	
	function updateField(ev) {

		// Convert value to number if an integer.
		let newVal = ev.target.value;

		updateDoc(f, newVal);
	}

	return html`
		<input
			class="field-${f}"
			type="color"
			value="${doc.curr[f]}"
			oninput="${updateField}"></input>
		<label>${fields[f].title}</label>
	`;

});

export default ColourField;