import {neverland as $, render, html, useState, useEffect} from 'neverland';

import Preview from './Collection_Doc_Preview.js';
import CheckboxField from './Collection_Doc_CheckboxField.js';
import TextField from './Collection_Doc_TextField.js';
import TextareaField from './Collection_Doc_TextareaField.js';
import DateField from './Collection_Doc_DateField.js';
import ColourField from './Collection_Doc_ColourField.js';
import SelectField from './Collection_Doc_SelectField.js';
import SequenceField from './Collection_Doc_SequenceField.js';

import DataIntegrityWizard from './DataIntegrityWizard.js';

function randomString() {
	return Math.random().toString().substr(2, 10);
}

import cloneDeep from 'lodash.clonedeep';

const Collection_Edit = $(function(auth, collection, setCollection) {
	
	let { 
			docID,
			edit,
			copy,
			preview,
			wizard,
			allDocs,
			fields
		} = collection,

		// Field names.
		flds = Object.keys(fields),

		isNew = docID.substr(0,3) == 'new';

	function getDefault(f, fields) {
	// Get default values for a particular field.

		if (fields[f].hasOwnProperty('default')) {
			if (typeof(fields[f].default) === "function") {
				return fields[f].default();
			} else {
				return fields[f].default;
			}
		} else {
			if (fields[f].type == 'select' && fields[f].multiple) {
				return [];
			} else if (fields[f].type == 'sequence') {
				return [];
			} else if (fields[f].type == 'checkbox') {
				return false;
			} else {
				return '';
			}
		}
	}

	function getDefaultElement(sequenceField) {
	// Generate a template element for sequence children with default values for each subfield.

		let sbflds = Object.keys(sequenceField.fields),
			subfields = sequenceField.fields;

		let template = {};
		for (let sf of sbflds) {
			template[sf] = getDefault(sf, subfields);
			if (subfields[sf].isReference) {
				template['collection'] = '';
			}
		}
		template['continuityId'] = randomString();
		return template;
	}

	function getDocTemplate() {
	// Generate a template document with default values for each field.

		let template = {};
		for (let f of flds) {
			template[f] = getDefault(f, fields);
		}
		return template;
	}

	function getFilledDocTemplate() {
	// Generate a template document with default values for each field, over-ridden by their current database values.
		
		let template = getDocTemplate(),
			thisDoc = allDocs.filter(d => (d.id == docID))[0];
		if (allDocs.map(d => d.id).includes(docID)) {
			
			// Populate level 1 template.
			template = {...template, ...thisDoc};

			// Populate level 2 template.
			for (let f of flds) {
				if (fields[f].type == 'sequence') {
					if (template[f].length > 0) {

						template[f] = template[f].map(d => {

							let dNew = {...getDefaultElement(fields[f]), ...d};

							// Populate level 3 template.
							let sbflds = Object.keys(fields[f].fields),
								subfields = fields[f].fields;
							for (let sf of sbflds) {
								if (subfields[sf].type == 'sequence') {
									if (dNew[sf].length > 0) {
										dNew[sf] = dNew[sf].map(d2 => {
											return {...getDefaultElement(subfields[sf]), ...d2};
										})
									}
								}
							}

							return dNew;
						});
						
					}
				}
			}
		}
		for (let f of flds) {
			if (fields[f].type == 'sequence') {
				template[f].forEach(function(el) {
					el['continuityId'] = randomString();
				})
			}
		}

		return template;
	}

	const [doc, setDoc] = useState(() => {
		let template = getFilledDocTemplate();
		return {
			prev: cloneDeep(template),
			curr: cloneDeep(template)
		}
	});

	useEffect(() => {
		let template = getFilledDocTemplate();
		setDoc(_ => {
			return {
				prev: cloneDeep(template),
				curr: cloneDeep(template)
			};
		})
	}, [docID])

	function cancel(ev = null) {

		removeSpuriousDivs();

		setDoc({
			prev: {...doc.prev},
			curr: {...doc.prev}
		})

		setCollection(prevCollection => {
			let collection = {...prevCollection};
			collection.edit = false;
			collection.copy = false;
			if (!collection.preview) {
				collection.docID = null;
			}
			return collection;
		})
	}

	function setsAreEqual(a1, a2) {
		const superSet = {};
		for (const i of a1) {
			const e = i + typeof i;
			superSet[e] = 1;
		}

		for (const i of a2) {
			const e = i + typeof i;
			if (!superSet[e]) {
				return false;
			}
			superSet[e] = 2;
		}

		for (let e in superSet) {
			if (superSet[e] === 1) {
				return false;
			}
		}

		return true;
	}

	function dictsAreEqual(o1, o2) {
		
		// Extract unique keys.
		let keys = Object.keys(o1).concat(Object.keys(o2));
		keys = [...new Set(keys)];

		let isDifference = false;

		for (let key of keys) {
			if (o1.hasOwnProperty(key) + o2.hasOwnProperty(key) != 2) {
				isDifference = true;
			} else if (o1[key] != o2[key]) {
				isDifference = true;
			}
		}

		return !isDifference;
	}

	function sequencesAreEqual(a1, a2) {
	// Return true if two arrays of single-layered objects are identical, otherwise false.

		if (a1.length != a2.length) {
		
			return false;
		
		} else {

			let isDifference = false;
			for (let k = 0; k < a1.length; k++) {
				if (!dictsAreEqual(a1[k], a2[k])) {
					isDifference = true;
				}
			}

			return !isDifference;
		}

	}

	function hasValue(f) {
	// Check if a field has a meaningful value.	
		if (fields[f].type == 'select' && fields[f].multiple) {
			return !setsAreEqual(doc.curr[f], getDefault(f, fields));
		} else if (fields[f].type == 'select') {
			return ![undefined, '', null].includes(doc.curr[f]);
		} else if (fields[f].type == 'sequence') {
			return doc.curr[f].length > 0;
		} else if (fields[f].type == 'checkbox') {
			return true;
		} else if (['text', 'textarea'].includes(fields[f].type)) {
			return doc.curr[f] != '';
		} else {
			return doc.curr[f] != getDefault(f, fields);
		}
	}

	function countErrors() {
	// Count fields that are required but currently have no value.

		let nErrors = 0;
		for (let f of flds) {
			if (!fieldIsHidden(f) && fields[f].required && !hasValue(f)) {
				let el = document.querySelector(`.field-box-${f}`);
				if (el !== null) {
					el.classList.add('error');
				}
				nErrors += 1;
			} else {
				let el = document.querySelector(`.field-box-${f}`)
				if (el !== null) {
					el.classList.remove('error');
				}
			}
		}

		return nErrors;
	}

	function didChange(f) {
	// Return true if field f has been changed, false otherwise.
		if (fields[f].type == 'select' && fields[f].multiple) {
			return !setsAreEqual(doc.curr[f], doc.prev[f]);
		} else if (fields[f].type == 'sequence') {
			return !sequencesAreEqual(doc.curr[f], doc.prev[f]);
		} else {
			return doc.curr[f] != doc.prev[f];
		}
	}

	function removeContinuityIds(obj) {
	// Remove any continuityIds (which are artefacts of the interface and not meaningful data).

		for (const f of Object.keys(obj)) {
			if (Array.isArray(obj[f])) {
				for (let f_idx = 0; f_idx < obj[f].length; f_idx++) {
					if (obj[f][f_idx].hasOwnProperty('continuityId')) {
						delete obj[f][f_idx].continuityId;
					}
					for (const sf of Object.keys(obj[f][f_idx])) {
						if (Array.isArray(obj[f][f_idx][sf])) {
							for (let sf_idx = 0; sf_idx < obj[f][f_idx][sf].length; sf_idx++) {
								if (obj[f][f_idx][sf][sf_idx].hasOwnProperty('continuityId')) {
									delete obj[f][f_idx][sf][sf_idx].continuityId;
								}
							}
						}
					}
				}
			}
		}
		return obj;
	}

	function removeSpuriousDivs() {
	// Remove spurious divs with iframes. (A bug with the Tripetto integration.)
	
		let divs = document.querySelectorAll('body > div');
		for (let k = 0; k < divs.length; k++) {
			if (!divs[k].classList.contains('main') && !divs[k].classList.contains('main-menu-toggle')) {
				divs[k].remove();
			}
		}
	}

	const [messages, setMessages] = useState([]);

	async function create() {

		removeSpuriousDivs();

		let nErrors = countErrors();

		// if (collection.onCopy.hasOwnProperty('check')) {
		// 	setMessages(collection.onCopy.check(doc));
		// 	nErrors += collection.onCopy.check(doc).length;
		// }

		if (nErrors == 0) {

			let newDoc = {};
			for (const f of flds) {
				if (hasValue(f)) {
					newDoc[f] = cloneDeep(doc.curr[f]);
				}
			}

			newDoc = removeContinuityIds(newDoc);

			if (copy | doc.curr.hasResponses) {
				if (collection.onCopy.hasOwnProperty('change')) {
					newDoc = collection.onCopy.change(newDoc);
				}
			}

			const result = await collection.mongo.insertOne(newDoc);
			
			newDoc._id = result.insertedId;
			newDoc.id = result.insertedId.toString();

			setDoc({
				prev: {...doc.prev},
				curr: {...doc.prev}
			})

			// Insert to local collection.

			setCollection(prevCollection => {
				let collection = {...prevCollection};
				collection.allDocs = [newDoc].concat(collection.allDocs);
				collection.filteredDocs = [newDoc].concat(collection.filteredDocs);
				// TODO - Resort/refilter filteredDocs.
				collection.docID = null;
				collection.edit = false;
				collection.copy = false;
				collection.preview = false;
				return collection;
			})

			// Insert to refCollection (and other stores), if relevant.

			if (Object.keys(collection.refCollections).includes(collection.collectionName)) {

				// Create reference doc object.
				
				let refDoc = cloneDeep(newDoc);
				refDoc.collection = collection.collectionName;
				refDoc.value = refDoc._id.toString();
				let lab = '',
					labelFormat = collection.refCollectionSpecs.filter(d => d.name == collection.collectionName)[0].labelFormat;
				labelFormat.forEach(function(lf) {
					let isUsed = lf.hasOwnProperty('if') ? refDoc.hasOwnProperty(lf.if) : true;
					if (isUsed) {
						if (lf.type == 'field') {
							lab += refDoc[lf.value];	
						} else if (lf.type == 'text') {
							lab += lf.value;
						}
					}
				})
				refDoc.label = lab;

				// Add to reference collection and full collection store.
				
				setCollection(prevCollection => {
					let collection = {...prevCollection};
					collection.refCollections[collection.collectionName].push(refDoc);
					if (collection.refCollectionSpecs.filter(d => d.name == collection.collectionName)[0].storeFullCollection) {
						collection.fullCollections[collection.collectionName].push(refDoc);
					}
					return collection;
				})

				// Add to options for any relevant select fields.

				setCollection(prevCollection => {
					let collection = {...prevCollection},
						{ fields } = collection;
					Object.keys(fields).forEach(function(f) {
						if (fields[f].isReference) {
							if (fields[f].optionCollections.includes(collection.collectionName)) {
								fields[f].options.push(refDoc);
							}
						} else if (fields[f].type == 'sequence') {
							let subfields = fields[f].fields;
							Object.keys(subfields).forEach(function(sf) {
								if (subfields[sf].isReference) {
									if (subfields[sf].optionCollections.includes(collection.collectionName)) {
										subfields[sf].options.push(refDoc);
									}
								} else if (subfields[sf].type == 'sequence') {
									let subsubfields = subfields[sf].fields;
									Object.keys(subsubfields).forEach(function(ssf) {
										if (subsubfields[ssf].isReference) {
											if (subsubfields[ssf].optionCollections.includes(collection.collectionName)) {
												subsubfields[ssf].options.push(refDoc);
											}
										}
									})
								}
							})
						}
					})
					return collection;
				})

				console.log(collection)
			}

		}
	}

	async function update() {

		removeSpuriousDivs();

		let nErrors = countErrors();
		// TODO - Check errors work for select fields (both types)

		if (nErrors == 0) {

			let changes = {};
			for (const f of flds) {
				if (didChange(f)) {
					changes[f] = cloneDeep(doc.curr[f]);
				}
			}

			changes = removeContinuityIds(changes);

			// TODO - delete field if content removed.

			console.log(changes);
			
			if (Object.keys(changes).length > 0) {
				// Changes were made, save changes.

				const result = await collection.mongo.updateOne(
					{ _id: doc.prev._id },
					{ $set: changes }
				);

				setCollection(prevCollection => {
					let collection = {...prevCollection};
					collection.allDocs = collection.allDocs.map(d => (d.id == docID) ? {...doc.curr} : d)
					collection.filteredDocs = collection.filteredDocs.map(d => (d.id == docID) ? {...doc.curr} : d)
					collection.docID = null;
					collection.edit = false;
					collection.copy = false;
					collection.preview = false;
					return collection;
				})

				// Update refCollection (and other locations), if relevant.

				if (Object.keys(collection.refCollections).includes(collection.collectionName)) {

					// Create reference doc object.
				
					let refDoc = cloneDeep(collection.fullCollections[collection.collectionName].filter(d => d.id == docID)[0]);
					refDoc = {...doc.curr, refDoc};
					refDoc.collection = collection.collectionName;
					refDoc.value = refDoc._id.toString();
					let lab = '',
						labelFormat = collection.refCollectionSpecs.filter(d => d.name == collection.collectionName)[0].labelFormat;
					labelFormat.forEach(function(lf) {
						let isUsed = lf.hasOwnProperty('if') ? refDoc.hasOwnProperty(lf.if) : true;
						if (isUsed) {
							if (lf.type == 'field') {
								lab += refDoc[lf.value];	
							} else if (lf.type == 'text') {
								lab += lf.value;
							}
						}
					})
					refDoc.label = lab;

					// Update reference collection and full collection store.
					
					setCollection(prevCollection => {
						let collection = {...prevCollection};
						collection.refCollections[collection.collectionName] = collection.refCollections[collection.collectionName].map(d => (d.id == docID) ? refDoc : d);
						if (collection.refCollectionSpecs.filter(d => d.name == collection.collectionName)[0].storeFullCollection) {
							collection.fullCollections[collection.collectionName] = collection.fullCollections[collection.collectionName].map(d => (d.id == docID) ? refDoc : d);
						}
						return collection;
					})

					// Add to options for any relevant select fields.

					setCollection(prevCollection => {
						let collection = {...prevCollection},
							{ fields } = collection;
						Object.keys(fields).forEach(function(f) {
							if (fields[f].isReference) {
								if (fields[f].optionCollections.includes(collection.collectionName)) {
									fields[f].options = fields[f].options.map(d => (d.id == docID) ? refDoc : d);
								}
							} else if (fields[f].type == 'sequence') {
								let subfields = fields[f].fields;
								Object.keys(subfields).forEach(function(sf) {
									if (subfields[sf].isReference) {
										if (subfields[sf].optionCollections.includes(collection.collectionName)) {
											subfields[sf].options = subfields[sf].options.map(d => (d.id == docID) ? refDoc : d);
										}
									} else if (subfields[sf].type == 'sequence') {
										let subsubfields = subfields[sf].fields;
										Object.keys(subsubfields).forEach(function(ssf) {
											if (subsubfields[ssf].isReference) {
												if (subsubfields[ssf].optionCollections.includes(collection.collectionName)) {
													subsubfields[ssf].options = subsubfields[ssf].options.map(d => (d.id == docID) ? refDoc : d);
												}
											}
										})
									}
								})
							}
						})
						return collection;
					})
				}

			} else {
				// No changes were made, just close the panel.
				cancel();
			}
		}
	}

	function handleChange() {
		setCollection(prevCollection => {
			let collection = {...prevCollection};
			collection.wizard = true;
			return collection;
		})
	}

	function handle(handler, wiz) {
		return async () => {

			switch (handler) {
				
				case 'update':
					
					await update();
					break;
				
				case 'copy':
					
					let newDoc = cloneDeep(doc.curr);

					// Update version of existing document.
					
					doc.curr = cloneDeep(doc.prev);
					doc.curr.version = wiz.prev.version;
					doc.curr.versionNotes = wiz.prev.versionNotes;
					
					await update();

					// Create new version.

					doc.curr = newDoc;
					doc.curr.version = wiz.curr.version;
					doc.curr.versionNotes = wiz.curr.versionNotes;
					
					await create();

					break;
					
				default:
					break;
			}
		}
	}

	function updateDoc(f, value) {
		setDoc(prevDoc => {
			let doc = {...prevDoc};
			doc.curr[f] = value;
			// console.log(value.map(d => d.label).join(' '));
			return doc;
		})
	}

	function updateDocByFunction(funct) {
		// console.log(funct)
		setDoc(funct);
	}

	function fieldIsHidden(f) {
		if (fields[f].hasOwnProperty('onlyShowIf')) {
			let { field, values } = fields[f].onlyShowIf;
			return !values.includes(doc.curr[field]);
		} else {
			return false;
		}
	}

	function Field(f) {
		// TODO - Fix autofocus
		switch(fields[f].type) {
			case 'checkbox':
				return html`${CheckboxField(f, fields, doc, updateDoc)}`;
				break;
			case 'text':
				return html`${TextField(f, fields, doc, updateDoc)}`;
				break;
			case 'textarea':
				return html`${TextareaField(f, fields, doc, updateDoc)}`;
				break;
			case 'date':
				return html`${DateField(f, fields, doc, updateDoc)}`;
				break;
			case 'colour':
				return html`${ColourField(f, fields, doc, updateDoc)}`;
				break;
			case 'select':
				return html`${SelectField(f, fields, doc, updateDoc, collection)}`;
				break;
			case 'sequence':
				return html`${SequenceField(f, fields, getDefault, doc, updateDoc, updateDocByFunction, collection)}`;
				break;
		}
	}

	return html`
		<aside>

			${(edit | copy) ? html`
				<div class="controls">

					<h1>${isNew ? 'Create' : (copy ? 'Copy' : 'Edit')} ${collection.nounSingular}</h1>

					<div class="buttons">
						<button class="" onclick="${cancel}">
							Cancel
						</button>
						<button class="blue" onclick="${isNew ? create : (copy ? create : (doc.curr.hasResponses ? handleChange : update))}">
							${isNew ? 'Create' : (copy ? 'Save as copy' : 'Save')}
						</button>
					</div>
				</div>

				<div class="fields custom-scrollbar">
					${flds.map(f => html`
						<div class="field-box field-box-${f} ${fields[f].hasOwnProperty('classes') ? fields[f].classes.join(' ') : ''} ${fieldIsHidden(f) ? 'hide' : ''}">
							${Field(f)}
						</div>`)}
				</div>

				<div class="messages hide">
					${messages.map(d => html`<div class="message">⚠ ${d.content}</div>`)}
				</div>
			` : ''}

			${preview ? Preview(
				collection,
				setCollection,
				doc
			) : ''}

		</aside>

		${wizard 
			? DataIntegrityWizard(auth, collection, setCollection, doc, updateDoc, handle)
			: html``}
	`;

});

export default Collection_Edit;