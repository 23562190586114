import {neverland as $, render, html, useState, useEffect} from 'neverland';

import Collection_Table from './Collection_Table.js';
import Collection_Edit from './Collection_Doc.js';
import Collection_Archive from './Collection_Archive.js';

import Select from './Select.js';
import TextInput from './TextInput.js';

function randomString() {
	return Math.random().toString().substr(2, 10);
}

function defaultFilters(specs) {
	let filters = {};
	for (let f of Object.keys(specs.fields)) {
		let field = specs.fields[f];
		if (field.type == 'select') {
			filters[f] = [];
		} else if (field.type == 'text' & field.filterable) {
			filters[f] = '';
		}
	}
	return filters;
}

const Collection = $(function(auth, specs) {
	
	let docsPerPage = 100;

	const [collection, setCollection] = useState(
		{
			...specs,
			mongo: auth.db.collection(specs.collectionName),

			refCollections: {},
			fullCollections: {},

			search: "",
			allDocs: [],
			filteredDocs: [],
			
			showFilters: false,
			filters: defaultFilters(specs),
			filterQueryId: randomString(),
			pageNum: 1,
			pageCount: 'many',
			pageDelimeters: [],

			docID: null,
			docPaneID: null,
			
			edit: false,
			copy: false,
			preview: false,
			wizard: false,

			showArchived: false,
			archiveID: null
		}
	);

	let toggleFilters = () => {
		setCollection(prevCollection => {
			let collection = {...prevCollection};
			collection.showFilters = !collection.showFilters;
			return collection;
		})
	}

	function setPage(incr) {
		return () => {
			setCollection(prevCollection => {
				let collection = {...prevCollection};
				collection.pageNum = Math.max(1, Math.min(collection.pageNum + incr, collection.pageCount));
				return collection;
			})
		}
	}

	function refilterDocs() {

		setCollection(prevCollection => {

			let collection = {...prevCollection},
				searchString = document.querySelector('#search').value.toLowerCase(),
				viewSpecs = collection.views[collection.views.default];
			
			// Apply search terms.
			collection.filteredDocs = collection.allDocs.filter(function(d) {
			
				let isMatch = false;
			
				for (let col of viewSpecs) {
					if (d.hasOwnProperty(col.key)) {
						if (typeof(d[col.key]) == "string") {
							isMatch = isMatch || d[col.key].toLowerCase().includes(searchString);
						}
					}
				}

				return isMatch;
			
			});

			// Apply archive.
			if (!collection.showArchived) {
				collection.filteredDocs = collection.filteredDocs.filter(function(d) {
					return !Boolean(d.archived);
				})
			}

			return collection;

		})
	}

	useEffect(() => {

		// Fetch total number of documents.
		collection.mongo.count()
			.then(data => {
				setCollection(prevCollection => {
					let collection = {...prevCollection};
					collection.pageCount = Math.ceil(data / docsPerPage);
					return collection;
				})
			})

		// Fetch documents.
		// console.log('FETCHING DOCUMENTS...')
		// collection.mongo.find({}, {sort: {_id: -1}, limit: docsPerPage})
		// 	.then(data => {
		// 		console.log('FETCHED.')
		// 		data = data.map(d => {
		// 			d.id = d._id.toString();
		// 			return d;
		// 		})
		// 		setCollection(prevCollection => {
		// 			let collection = {...prevCollection};
		// 			collection.allDocs = data;
		// 			collection.filteredDocs = data;
		// 			collection.pageDelimeters.push(data[data.length - 1]._id);
		// 			return collection;
		// 		})
		// 		refilterDocs();
		// 	})

		if (collection.refCollectionSpecs) {
			
			console.log('FETCHING REFERENCE COLLECTIONS...')
			let promises = [];
			for (let c of collection.refCollectionSpecs) {
				let mongo = auth.db.collection(c.name);
				promises.push(mongo.find()
					.then(data => {
						console.log(`   FETCHED: ${c.name}`)
						let refData = data.map(d => {
							d.id = d._id.toString();
							d.value = d._id.toString();
							let lab = '';
							c.labelFormat.forEach(function(lf) {
								let isUsed = lf.hasOwnProperty('if') ? (d.hasOwnProperty(lf.if) ? d[lf.if] !== '' : false) : true;
								if (isUsed) {
									if (lf.type == 'field') {
										lab += d[lf.value];	
									} else if (lf.type == 'text') {
										lab += lf.value;
									}
								}
							})
							d.label = lab;
							d.collection = c.name;
							d.disabled = Boolean(d.archived);
							return d;
						})
						setCollection(prevCollection => {
							let collection = {...prevCollection};
							collection.refCollections[c.name] = refData;
							if (c.storeFullCollection) {
								collection.fullCollections[c.name] = data;
								}
							// console.log(data);
							return collection;
						})
						return data;
					})
				);
			}

			Promise.all(promises)
				.then((values) => {
					setCollection(prevCollection => {
						let collection = {...prevCollection},
							{ fields } = collection;
						Object.keys(fields).forEach(function(f) {
							if (fields[f].isReference) {
								let options = [];
								for (let refCollectionName of fields[f].options) {
									options = options.concat(collection.refCollections[refCollectionName]);
								}
								fields[f].optionCollections = [...fields[f].options];
								fields[f].options = options;
							} else if (fields[f].type == 'sequence') {
								let subfields = fields[f].fields;
								Object.keys(subfields).forEach(function(sf) {
									if (subfields[sf].isReference) {
										let options = [];
										for (let refCollectionName of subfields[sf].options) {
											options = options.concat(collection.refCollections[refCollectionName]);
										}
										subfields[sf].optionCollections = [...subfields[sf].options];
										subfields[sf].options = options;
									} else if (subfields[sf].type == 'sequence') {
										let subsubfields = subfields[sf].fields;
										Object.keys(subsubfields).forEach(function(ssf) {
											if (subsubfields[ssf].isReference) {
												let options = [];
												for (let refCollectionName of subsubfields[ssf].options) {
													options = options.concat(collection.refCollections[refCollectionName]);
												}
												subsubfields[ssf].optionCollections = [...subsubfields[ssf].options];
												subsubfields[ssf].options = options;
											}
										})
									}
								})
							}
						})
						return collection;
					})

				})
		}
	}, [])


	useEffect(() => {
		
		let {
				pageNum,
				pageDelimeters
			} = collection,
			{ fields } = specs,
			filters = {};

		// Define MongoDB query filters.
		for (let f of Object.keys(collection.filters)) {
			let filter = collection.filters[f];
			
			switch(fields[f].type) {
				case 'select':
					if (filter.length > 0) {
						filters[f] = { $in: filter };
					}
					break;
				case 'text':
					if (filter.length > 0) {
						// filters['$text'] = { $search: filter };
						filters[f] = { $regex: new RegExp(filter, "i") }
					}	
					break;
				default:
					break;
			}
		}

		// Fetch total number of documents.
		collection.mongo.count(filters)
			.then(data => {
				setCollection(prevCollection => {
					let collection = {...prevCollection};
					collection.pageCount = Math.max(1, Math.ceil(data / docsPerPage));
					return collection;
				})
			})

		// Add additional filter for relevant page of results.;
		if (pageNum > 1) {
			filters._id = {$lt: pageDelimeters[pageNum - 2]};
		}
		
		// console.log('New pageNum: ' + pageNum);

		console.log(`FETCHING DOCUMENTS (Page ${pageNum})...`);
		collection.mongo.find(filters, {sort: {_id: -1}, limit: docsPerPage})
			.then(data => {
				console.log('FETCHED.')
				data = data.map(d => {
					d.id = d._id.toString();
					return d;
				})
				setCollection(prevCollection => {
					let collection = {...prevCollection};
					collection.allDocs = data;
					collection.filteredDocs = data;
					if (pageNum > collection.pageDelimeters.length & data.length > 0) {
						collection.pageDelimeters.push(data[data.length - 1]._id);
					}
					return collection;
				})
				refilterDocs();
			})

	}, [collection.pageNum, collection.filterQueryId])


	function refilterDocs() {

		setCollection(prevCollection => {

			let collection = {...prevCollection},
				searchString = document.querySelector('#search').value.toLowerCase(),
				viewSpecs = collection.views[collection.views.default];
			
			// Apply search terms.
			collection.filteredDocs = collection.allDocs.filter(function(d) {
			
				let isMatch = false;
			
				for (let col of viewSpecs) {
					if (d.hasOwnProperty(col.key)) {
						if (typeof(d[col.key]) == "string") {
							isMatch = isMatch || d[col.key].toLowerCase().includes(searchString);
						}
					}
				}

				return isMatch;
			
			});

			// Apply archive.
			if (!collection.showArchived) {
				collection.filteredDocs = collection.filteredDocs.filter(function(d) {
					return !Boolean(d.archived);
				})
			}

			return collection;

		})
	}

	useEffect(() => {
		refilterDocs()
	}, [
		collection.showArchived,
		collection.archiveID
	])

	function toggleArchived() {
		setCollection(prevCollection => {
			let collection = {...prevCollection};
			collection.showArchived = !collection.showArchived;
			return collection;
		})
	}

	function create() {
		setCollection(prevCollection => {
			let collection = {...prevCollection};
			collection.docID = 'new' + Math.random().toString(20).substr(2, 10);
			if (['Items','ItemSets'].includes(collection.collectionName)) {
				collection.preview = true;
			}
			collection.edit = true;
			collection.docPaneID = Math.random().toString(20).substr(2, 10);
			return collection;
		})
	}

	// <div class="counts">${collection.filteredDocs.length}<span>/</span>${collection.allDocs.length}</div>

	function FilterField(f) {

		let field = collection.fields[f];

		switch(field.type) {
			case 'text':
				return html`<div class="filter">
					<div class="text">
						${TextInput(
							collection.filters[f],
							val => setCollection(prevCollection => {
								let collection = {...prevCollection};
								collection.filters[f] = val;
								return collection;
							})
						)}
						<button onclick=${() => {
							setCollection(prevCollection => {
								let collection = {...prevCollection};
								collection.pageNum = 1;
								collection.pageDelimeters = [];
								collection.filterQueryId = randomString();
								return collection;
							})
						}}>🔍</button>
					</div>
					<label>${field.title}</label>
				</div>`;
				break;
			case 'select':
				return html`<div class="filter">
					${Select(
						field.options,
						collection.filters[f],
						val => {
							setCollection(prevCollection => {
								let collection = {...prevCollection};
								collection.filters[f] = val;
								collection.pageNum = 1;
								collection.pageDelimeters = [];
								collection.filterQueryId = randomString();
								return collection;
							})
						},
						undefined,
						undefined,
						true,
						true)}
					<label>${field.title}</label>
				</div>`;
				break;
			default:
				return html``;
				break;
		}
	}

	return html`
		<div class="main">

			<div class="controls">
				<button class="filter ${collection.showFilters ? 'active' : ''}" onclick="${toggleFilters}">≡</button>
				<input
					type="text"
					id="search"
					placeholder="Type to search..."
					autofocus
					oninput="${refilterDocs}">
				</input>
				
				<button class="pagination" onclick="${setPage(-1)}">◄</button>
				<div class="pagination">
					 Page ${collection.pageNum}
					of ${collection.pageCount}</div>
				<button class="pagination" onclick="${setPage(1)}">►</button>
				
				<button class="green" onclick="${create}">
					Create new
				</button>
			</div>

			<div class="filters ${collection.showFilters ? '' : 'hide'}">

				${Object.keys(collection.fields).filter(f => (collection.fields[f].type == 'select' && typeof(collection.fields[f].options[0]) == 'object') | collection.fields[f].type == 'text' & collection.fields[f].filterable).map(f => FilterField(f))}

				<div class="filter">
					<button onclick="${toggleArchived}">
						${collection.showArchived ? 'Hide' : 'Show'} archived
					</button>
				</div>
			</div>

			${Collection_Table(collection, setCollection)}

		</div>	

		${(collection.docID === null)
			? html``
			: Collection_Edit(auth, collection, setCollection)}

		${(collection.archiveID === null)
			? html``
			: Collection_Archive(auth, collection, setCollection)}

	`;

});

export default Collection;