import {neverland as $, render, html, useState, useEffect} from 'neverland';

function randomString() {
	return Math.random().toString().substr(2, 10);
}

const Select = $(function(options, value, updateValue, groupByCollection, optionCollections, searchEnabled, multiple, hideOptions = false) {

	let [open, setOpen] = useState(false);
	let [keywords, setKeywords] = useState('');
	let [id] = useState(`select-${randomString()}`);

	let toggleOpen = () => {
		setOpen(!open);
	}

	function group(opts) {
		if (groupByCollection) {
			let rval = [];
			for (let c of optionCollections) {
				if (opts.filter(opt => opt.collection == c).length > 0) {
					rval.push({
						heading: c
					})
					rval = rval.concat(opts.filter(opt => opt.collection == c))
				}
			}
			return rval;
		} else {
			return opts;
		}
	}

	function filter(opts) {

		if (searchEnabled) {
			return opts.filter(opt => opt.label.toLowerCase().includes(keywords.toLowerCase()));
		} else {
			return opts;
		}
	}

	function clear() {
		updateValue(multiple ? [] : '');
		setKeywords('');
		setOpen(false);
	}

	function unselect(val) {
		return () => {
			updateValue(value.filter(d => d != val));
		}
	}

	function getLabel(val) {
		let matchingOptions = options.filter(d => d.value == val);
		if (matchingOptions.length > 0) {
			return matchingOptions[0].label;
		} else {
			return '';
		}
	}

	function multiselectedValue(val) {
		return html`<div class="value">
			<div class="label">${getLabel(val)}</div>
			<div class="unselect" onclick="${unselect(val)}">×</div>
		</div>`;
	}

	function Option(opt) {
		if (opt.heading) {
			return html`<div class="heading">${opt.heading}</div>`;
		} else {
			return html`<div class="option ${opt.disabled ? 'disabled' : ''} ${multiple ? (value.includes(opt.value) ? 'active' : '') : (opt.value == value ? 'active' : '')}" onclick="${() => {
				if (!opt.disabled) {

					let newVal;
					if (multiple) {
						if (value.includes(opt.value)) {
							newVal = value.filter(d => d != opt.value);
						} else {
							newVal = value.concat([opt.value]);		
						}
					} else {
						newVal = opt.value;
					}
					
					updateValue(newVal);
					setKeywords('');
					if (!multiple) {
						setOpen(false);
					}

				}
			}}">${opt.label}</div>`
		}
	}

	let close = () => {
		setOpen(false);
	}

	// Focus on search box on open.
	useEffect(() => {
		if (open & searchEnabled) {
			document.querySelector(`#${id} input[type="text"]`).focus();
		}
	})

 	if (multiple) {

		return html`<div class="curtain ${open ? '' : 'hide'}" onclick="${close}"></div>
		<div id="${id}" class="select">

			<div class="input">
				<div class="multiselected" onclick="${toggleOpen}">${value.map(d => multiselectedValue(d))}</div>
				<div class="icon" onclick="${clear}">×</div>
				<div class="icon black" onclick="${toggleOpen}">${open ? '▴' : '▾'}</div>
			</div>
		
			<div class="dropdown ${open ? '' : 'invisible'}">
				${searchEnabled ? html`<input
					type="text"
					placeholder="Search..."
					autofocus=${open}
					value="${keywords}"
					oninput="${(evt) => {setKeywords(evt.target.value)}}">
				</input>` : ''}
				<div class="options">
					${hideOptions & !keywords ? html`` : group(filter(options)).map(opt => Option(opt))}
				</div>
			</div>
		
		</div>`;		

	} else {

		return html`<div class="curtain ${open ? '' : 'hide'}" onclick="${close}"></div>
		<div id="${id}" class="select">

			<div class="input">
				<div class="selected" onclick="${toggleOpen}">${getLabel(value)}</div>
				<div class="icon" onclick="${clear}">×</div>
				<div class="icon black" onclick="${toggleOpen}">${open ? '▴' : '▾'}</div>
			</div>
		
			<div class="dropdown ${open ? '' : 'invisible'}">
				${searchEnabled ? html`<input
					type="text"
					placeholder="Search..."
					autofocus=${open}
					value="${keywords}"
					oninput="${(evt) => {setKeywords(evt.target.value)}}">
				</input>` : ''}
				<div class="options">
					${hideOptions & !keywords ? html`` : group(filter(options)).map(opt => Option(opt))}
				</div>
			</div>
		
		</div>`;

	}


})

export default Select;

// TODO
// - other ideas from https://react-select.com/home