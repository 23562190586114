import {neverland as $, render, html, useState, useEffect} from 'neverland';

import Select from './Select.js';

function isInteger(value) {
	return /^\d+$/.test(value);
}

function fixType(value) {
	if (value == "true") {
		return true;
	} else if (value == "false") {
		return false;
	} else if (isInteger(value)) {
		return Number(value);
	} else {
		return value;
	}
}

const SelectSubSubField = function(f, fields, f_idx, sf, sf_idx, ssf, doc, updateDocByFunction, collection, sequenceUpdateId, continuityId) {

	let subfields = fields[f].fields;

	// ------------------------------------------------------------------
	// Handle dynamic options (for logic fields).

	let options = [];

	if (sf == "conditions" && ssf == "item") {
	
		let prevItemsInSet = [],
			maxRecursiveCalls = 20,
			nRecursiveCalls = 0;

		function getItems(seq) {

			nRecursiveCalls++;

			if (nRecursiveCalls >= maxRecursiveCalls) {
				console.log('Maximum recursive calls reached.')
			}

			for (let el of seq) {

				// Handle differently if Item or ItemSet.
				if (el.collection == "Items") {
					
					// Push Item details to items.
					let matchingItems = collection.fullCollections['Items'].filter(d => d.id == el.element);
					if (matchingItems.length > 0) {
						prevItemsInSet.push(matchingItems[0]);
					}

				} else if (el.collection == "ItemSets") {

					// Extract elements in ItemSet and recursively call getItems on it.
					let matchingItemSets = collection.fullCollections['ItemSets'].filter(d => d.id == el.element);
					if (matchingItemSets.length > 0 && nRecursiveCalls < maxRecursiveCalls) {
						
						getItems(matchingItemSets[0].elements);
						
					}
				}

			}

		}

		getItems(doc.curr.elements.slice(0, f_idx));

		// For now, only allow choose-1 or dropdown-1 types.
		prevItemsInSet = prevItemsInSet.filter(d => ['choose-1', 'dropdown-1'].includes(d.type));

		let optIDs = prevItemsInSet.map(d => d.value);
		options = subfields[sf].fields[ssf].options.filter(d => optIDs.includes(d.value));

	} else if (sf == "conditions" && ssf == "value") {

		let matchingItems = collection.fullCollections['Items'].filter(d => d.id == doc.curr[f][f_idx][sf][sf_idx].item);
		if (matchingItems.length > 0) {
			options = matchingItems[0].options;
		}

	} else {
		
		options = subfields[sf].fields[ssf].options;
	
	}
	
	// ------------------------------------------------------------------	

	function updateSubSubField(newVal) {

		if (subfields[sf].fields[ssf].multiple) {
			newVal = newVal.map(v => fixType(v));
		} else {
			newVal = fixType(newVal);
		}

		updateDocByFunction(prevDoc => {
			let doc = {...prevDoc},
				seq = [...doc.curr[f][f_idx][sf]];
			seq[sf_idx][ssf] = newVal;
			if (fields[f].fields[sf].fields[ssf].isReference) {
				if (newVal == '') {
					seq[sf_idx].collection = null;	
				} else {
					seq[sf_idx].collection = options.filter(d => d.value == newVal)[0].collection;
				}
			}
			doc.curr[f][f_idx][sf] = seq;
			return doc;	
		})
	}

	return html`
		<label>${subfields[sf].fields[ssf].title}</label>
		${Select(
			options,
			doc.curr[f][f_idx][sf][sf_idx][ssf],
			updateSubSubField,
			subfields[sf].fields[ssf].groupByCollection,
			subfields[sf].fields[ssf].optionCollections,
			subfields[sf].fields[ssf].searchEnabled,
			subfields[sf].fields[ssf].multiple,
			subfields[sf].fields[ssf].hideOptions
			)}
	`;

	// ${!subfields[sf].fields[ssf].multiple ? html`<option value="">&nbsp;</option>` : ''}
};

export default SelectSubSubField;