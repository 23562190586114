import {neverland as $, render, html, useState} from 'neverland';

const CheckEmailPage = $(function() {
	
	return html`
		<div class='login-fields'>

			We've sent you an email! Check your inbox for a password reset link.

		</div>
	`;

});

export default CheckEmailPage;