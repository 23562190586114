import {neverland as $, render, html, useState, useEffect} from 'neverland';

import Select from './Select.js';

import cloneDeep from 'lodash.clonedeep';

const DataIntegrityWizard = $(function(auth, collection, setCollection, doc, updateDoc, handle) {
	
	let {
		fields
	} = collection;

	const [advanced, setAdvanced] = useState(true);

	const [handler, setHandler] = useState('copy');

	function defaultWiz() {
		return {
			prev: {
				version: doc.prev.version,
				versionNotes: doc.prev.versionNotes
			},
			curr: {
				version: doc.curr.version,
				versionNotes: doc.curr.versionNotes
			}
		}
	}

	const [wiz, setWiz] = useState(defaultWiz());

	useEffect(() => {
		if (!advanced & handler != 'copy') {
			setHandler('copy');
		}
	}, [advanced])

	function cancel(ev) {
		setCollection(prevCollection => {
			let collection = {...prevCollection};
			collection.wizard = false;
			return collection;
		})
		setWiz(defaultWiz());
		setHandler('copy');
	}

	// async function toggleArchiveStatus(ev) {

	// 	const result = await collection.mongo.updateOne(
	// 		{ _id: doc._id },
	// 		{ $set: { archived: !Boolean(doc.archived) } }
	// 	);
		
	// 	setCollection(prevCollection => {
	// 		let collection = {...prevCollection};
	// 		collection.allDocs.forEach(d => {
	// 			if (d.id == archiveID) {
	// 				d.archived = !Boolean(doc.archived)
	// 			}
	// 		})
	// 		collection.archiveID = null;
	// 		return collection;
	// 	})
	// }

	let handlers = [
		{
			value: "copy",
			label: "...creating a new version. (RECOMMENDED)",
			warnings: []

		},
		{
			value: "update",
			label: "...overwriting the existing version.",
			warnings: [
				'Any Item Sets that contain this <DOCUMENT> as an element will start using the overwritten version, effective immediately.',
				'Any Surveys that use this <DOCUMENT> will start using the overwritten version, effective immediately.',
				'Responses to this <DOCUMENT> that have already been collected will no longer have an accurate record of what <DOCUMENT> was seen by the respondent.'
			]
		},
		{
			value: "copy-propagate",
			label: "...creating a new version, and propagating that new version through to other Item Sets and/or Surveys.",
			disabled: true,
			warnings: []
		}
	];

	function getHandler() {
		return handlers.filter(d => d.value == handler)[0];
	}

	return html`
		<div class="shades">

			<div class="modal large">
				<p><strong>This ${collection.nounSingular} has responses.</strong></p>

				<p>When making changes, it is important to maintain the integrity of our dataset.</p>

				<div class="advanced ${advanced ? '' : 'hide'}">
					<p>Implement your change(s) by...</p>
					${Select(handlers, handler, setHandler, false, {}, false, false)}
				</div>

				<div class="warnings ${getHandler().warnings.length > 0 ? '' : 'hide'}">
					<p><strong>Be careful!</strong> If you proceed with this option:</p>
					<ul>
						${getHandler().warnings.map(d => html`<li>${d.replace(/<DOCUMENT>/g, collection.nounSingular)}</li>`)}
					</ul>
				</div>

				<p class="${handler == 'update' ? 'hide' : ''}">Tweak the text below so we can tell the two versions apart.</p>

				<div class="version-fields ${handler == 'update' ? 'hide existing-only' : ''}">

					<div></div>

					<div class="colHead c1">Existing version</div>
					<div class="colHead c2 new">New version</div>

					<div class="rowHead">Version<br />Identifier</div>

					<input
						type="text"
						value="${wiz.prev.version}"
						oninput="${(evt) => {
							setWiz(prevWiz => {
								let wiz = {...prevWiz};
								wiz.prev.version = evt.target.value;
								return wiz;
							})
						}}"></input>

					<input
						type="text"
						class="new"
						value="${wiz.curr.version}"
						oninput="${(evt) => {
							setWiz(prevWiz => {
								let wiz = {...prevWiz};
								wiz.curr.version = evt.target.value;
								return wiz;
							})
						}}"></input>

					<div class="rowHead">Version<br />Description</div>

					<textarea
						oninput="${(evt) => {
							setWiz(prevWiz => {
								let wiz = {...prevWiz};
								wiz.prev.versionNotes = evt.target.value;
								return wiz;
							})
						}}">${wiz.prev.versionNotes}</textarea>

					<textarea
						oninput="${(evt) => {
							setWiz(prevWiz => {
								let wiz = {...prevWiz};
								wiz.curr.versionNotes = evt.target.value;
								return wiz;
							})
						}}">${wiz.curr.versionNotes}</textarea>
				</div>


				<button class="muted" onclick="${cancel}">Cancel</button>
				<button
					class="${handler != 'copy' ? 'red' : 'blue'}"
					onclick="${handle(handler, wiz)}">
						${handler == 'update' ? "Overwrite" : 'Create new version'}
					</button>

			</div>

		</div>
	`;

	// Unused button for toggling advanced options: <button class="grey" onclick="${() => setAdvanced(!advanced)}">${advanced ? 'Hide' : 'Show'} advanced options ${advanced ? html`&#708;` : html`&#709;`}</button>

});

export default DataIntegrityWizard;