import {neverland as $, render, html, useState} from 'neverland';

const Menu = $(function(auth, setAuth) {
	
	const [isHidden, setHidden] = useState(false);

	async function logout() {
		
		await auth.app.currentUser.logOut();

		setAuth(prevAuth => {
			let auth = {...prevAuth};
			auth.isLoggedIn = false;
			auth.page = undefined;
			return auth;
		})

		window.history.pushState({}, `Log in`, `/`);
	}

	// function GoToPage(page) {
	// 	return function() {
	// 		window.history.pushState({}, `RY ${page}`, `/${page}/`);
	// 		// setAuth(prevAuth => {
	// 		// 	let auth = {...prevAuth};
	// 		// 	auth.page = page;
	// 		// 	return auth;
	// 		// })
	// 	}
	// }

	const toggleMainMenu = () => {
		setHidden(isHidden => !isHidden);
	}

	return html`
		<div
			id="main-menu-toggle"
			class="main-menu-toggle"
			onclick="${toggleMainMenu}"></div>

		<nav id="main-menu" class="main-menu ${isHidden && 'hide'}">

			<div>

				<a href="/?p=ClientGroups" class="${auth.page == "ClientGroups" ? "here" : null}"><span>
					Client Groups
				</span></a>
				
				<!--<button class="indent">
					<span>
						Add
					</span>
				</button>-->

				<hr />

				<a href="/?p=Items" class="${auth.page == "Items" ? "here" : null}"><span>
					Items
				</span></a>

				<a href="/?p=ItemSets" class="${auth.page == "ItemSets" ? "here" : null}"><span>
					Item Sets
				</span></a>

				<hr />

				<a href="/?p=Surveys" class="${auth.page == "Surveys" ? "here" : null}"><span>
					Surveys
				</span></a>

				<hr />

				<a href="/?p=Responses" class="${auth.page == "Responses" ? "here" : null}"><span>
					Responses
				</span></a>

				<hr />
	
				<a href="/?p=Themes" class="${auth.page == "Themes" ? "here" : null}"><span>
					Themes
				</span></a>

				<a href="/?p=EndScreens" class="${auth.page == "EndScreens" ? "here" : null}"><span>
					End Screens
				</span></a>

			</div>

			<div>
				<strong>${auth.app.currentUser._profile.email}</strong>
				<button onclick="${logout}">
					<span>
						Log out
					</span>
				</button>
			</div>
			

		</nav>`;

});

export default Menu;