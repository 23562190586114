import {neverland as $, render, html, useState, useEffect} from 'neverland';

import buildForm from '../tripetto-constructors/buildForm.js';
import { run } from "tripetto-runner-autoscroll";

function randomString() {
	return Math.random().toString().substr(2, 10);
}

const Preview = $(function(
	collection,
	setCollection,
	doc
	) {

	let { 
			preview,
			edit,
			docPaneID
		} = collection;

	function openEditor() {
		setCollection(prevCollection => {
			let collection = {...prevCollection};
			collection.edit = true;
			return collection;
		})
	}

	function closePreview() {

		// Remove iframe.
		document.querySelector('iframe').remove();

		setCollection(prevCollection => {
			let collection = {...prevCollection};
			collection.preview = false;
			if (!collection.edit) {
				collection.docID = null;
			}
			return collection;
		})
	}

	// Initialise Tripetto.
	useEffect(() => {

		if (preview) {

			let seq = doc.curr;
			if (collection.title == "Items") {
				seq.isItem = true;
				if (!seq.hasOwnProperty('id')) {
					seq.id = randomString();
				}
				seq = [seq];
			} else if (collection.title == "Item Sets") {
				seq = seq.elements;
			}

			let spec = {
				Items: collection.fullCollections.Items,
				ItemSets: collection.fullCollections.ItemSets,
				ItemSet: {
					elements: seq
				}
			};


			for (let k = 0; k < 2; k++) { // Run twice to overcome a bug in which the React component did not update properly.
				run({
					element: document.querySelector('.preview'), /* Or supply your own element here */
					definition: buildForm(spec),
					styles: {
						direction: "vertical",
						verticalAlignment: "middle",
						hidePast: true,
						hideUpcoming: true,
						showNavigation: "never",
						showProgressbar: false,
						showEnumerators: false,
						autoFocus: false,
						showSeparateSubmit: true,
						showPreviousButton: true,
						showScrollbar: false,
						noBranding: true,
						hideRequiredIndicator: true,
						contract: {
							name: "tripetto-runner-autoscroll",
							version: "5.1.2"
						}
					},
					onSubmit: (instance) => {
						// TODO: Handle the results
						// For example retrieve the results as a CSV-file:
						// const csv = Export.CSV(instance);
						// // Or retrieve the individual fields:
						// const fields = Export.fields(instance);
					}
				});
			}

		}


	}, [preview,
		docPaneID,
		doc.curr.type,
		doc.curr.prompt,
		doc.curr.caption,
		doc.curr.explanation,
		doc.curr.description,
		doc.curr.options,
		doc.curr.precision,
		doc.curr.elements])

	// Add custom CSS.
	useEffect(() => {
		let iframe = document.querySelector(".preview iframe");
		if (iframe !== null) {
			let sheets = document.styleSheets,
				existingLinks = iframe.contentDocument.querySelectorAll("link");
			
			if (existingLinks.length == 0) {
				// Re-add stylesheets.
				for (let { href } of sheets) {
					if (href !== null) {
						const link = document.createElement("link");
						link.href = href;
						link.rel = "stylesheet";
						iframe.contentDocument.body.appendChild(link);
					}
				}
			}
		}
	}, [collection.docPaneID])

	return html`
		<div class="controls">

			<h1>Preview</h1>

			<div class="buttons">
				<button class="" onclick="${closePreview}">
					Close
				</button>
				${!edit ? html`
					<button class="blue" onclick="${openEditor}">
						Edit
					</button>
				` : ''}
			</div>
		</div>

		<div class="preview"></div>
	`;

});

export default Preview;