import {neverland as $, render, html, useState} from 'neverland';

const ResetPasswordPage = $(function(auth, setAuth) {
	
	async function tryReset() {

		document.getElementById('error-dontmatch').classList.add('hide');
		document.getElementById('error-generic').classList.add('hide');

		let queryString = window.location.search,
			urlParams = new URLSearchParams(queryString),
			token = urlParams.get('token'),
			tokenId = urlParams.get('tokenId');

		console.log(token)
		console.log(tokenId)

		let pw1 = document.getElementById('pw1').value,
			pw2 = document.getElementById('pw2').value;

		if (pw1 !== pw2) {
			document.getElementById('error-dontmatch').classList.remove('hide');
			return;
		}

		try {
			
			await auth.app.emailPasswordAuth.resetPassword(token, tokenId, pw1);

			window.history.replaceState({}, "New Password", "/");

			setAuth(prevAuth => {
				let auth = {...prevAuth};
				auth.publicPage = 'ResetSuccess';
				return auth;
			})

		
		} catch(err) {

			console.warn(err);
			document.getElementById('error-generic').classList.remove('hide');
		
		}
	}

	function clickReset(event) {
		// Number 13 is the "Enter" key on the keyboard
		if (event.keyCode === 13) {
			event.preventDefault();
			document.getElementById("reset-button").click();
		}
	}

	return html`
		<div class='login-fields'>

			<label>New Password</label>
			<input id="pw1" type="password" autofocus />

			<label>Confirm New Password</label>
			<input id="pw2" type="password" onkeyup="${clickReset}" />

			<button id="reset-button" onclick="${tryReset}">Reset password</button>

			<div id="error-dontmatch" class="login-error hide">Passwords do not match</div>
			<div id="error-generic" class="login-error hide">Something went wrong</div>

		</div>
	`;

});

export default ResetPasswordPage;