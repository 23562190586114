import {neverland as $, render, html, useState, useEffect} from 'neverland';
import Masonry from 'masonry-layout';

const Collection_Table = $(function(collection, setCollection) {
	
	let docs = collection.filteredDocs,
		fields = collection.fields,
		specs = collection.views[collection.views.default];

	function getColWidths(specs) {
		let ws = '';
		for (let col of specs) {
			ws = ws + col.w + '% ';
		}
		return ws;
	}

	let colWidths = getColWidths(specs);


	let [msnry, setMsnry] = useState(null);
	
	useEffect(() => {
		
		if (collection.views.default == 'masonry') {

			if (msnry === null) {

				msnry = new Masonry( '.masonry-wrapper', {
					itemSelector: '.row',
					columnWidth: '.row-sizer',
					percentPosition: true,
					transitionDuration: 0
				});

			}

			if (msnry !== null) {
				setTimeout(() => {
					msnry.layout();
				}, 100)
			}
			
		}

	})

	function preview(id) {
		return (ev) => {
			setCollection(prevCollection => {
				let collection = {...prevCollection};
				collection.docID = id;
				collection.preview = true;
				collection.copy = false;
				collection.docPaneID = Math.random().toString(20).substr(2, 10);
				return collection;
			})
		}
	}

	function copy(id) {
		return (ev) => {
			setCollection(prevCollection => {
				let collection = {...prevCollection};
				collection.docID = id;
				collection.copy = true;
				collection.edit = false;
				collection.preview = false;
				collection.docPaneID = Math.random().toString(20).substr(2, 10);
				return collection;
			})
		}
	}

	function edit(id) {
		return (ev) => {
			setCollection(prevCollection => {
				let collection = {...prevCollection};
				collection.docID = id;
				collection.edit = true;
				collection.copy = false;
				collection.docPaneID = Math.random().toString(20).substr(2, 10);
				return collection;
			})
		}
	}

	function archive(id) {
		return (ev) => {
			setCollection(prevCollection => {
				let collection = {...prevCollection};
				collection.archiveID = id;
				collection.docID = null;
				collection.edit = false;
				collection.copy = false;
				collection.preview = false;
				return collection;
			})
		}
	}

	function Header() {
		return html`
			<div class="row header-row">
				<div class="row-data" style="grid-template-columns: ${colWidths};">
					${specs.map(col => html`<div class="col">${fields[col.key].title}</div>`)}
				</div>
				<div class="row-buttons">
					${collection.views.form ? html`<button class="invisible">View</button>` : ''}
					<button class="invisible">Edit</button>
					<button class="invisible">Copy</button>
					<button class="invisible">Archive</button>
				</div>
			</div>
		`;
	}

	function getLabel(value, f) {
		let matchingOpts = fields[f].options.filter(opt => opt.value == value);
		if (matchingOpts.length == 0) {
			// NO MATCHING LABEL
			return '';
		} else if (matchingOpts.length > 1) {
			// >1 MATCHING LABEL
			return '';
		} else {
			return matchingOpts[0].label;
		}
	}

	function Jellybean(opt, f) {
		let label = getLabel(opt, f);
		return html`<div class="jellybean">${label}</div>`;
	}

	function CellContent(doc, f) {
		if (fields[f].type == 'select') {
			
			if (fields[f].multiple) {
				return html`${doc[f].map(opt => Jellybean(opt, f))}`
			} else {
				return getLabel(doc[f], f);
			}

		} else {
			return doc[f];
		}
	}

	function Row(doc) {
		// ${collection.views.form ? 'clickable' : ''}
		// onclick="${collection.views.form ? preview(doc.id) : ''}"
		return html.for(doc)`
			<div class="row ${doc.id == collection.docID ? 'being-edited' : ''} ${doc.archived ? 'archived' : ''}">
				<div class="row-data" style="grid-template-columns: ${colWidths};">
					${specs.map(col => html`
						<span class="key">${col.key}</span>
						<div class="col ${fields[col.key].hasOwnProperty('classes') ? fields[col.key].classes.join(' ') : ''} ${fields[col.key].type == 'select' && fields[col.key].multiple ? 'jar-of-jellybeans' : ''}">
							${CellContent(doc, col.key)}
						</div>
					`)}
				</div>
				<div class="row-buttons">
					${collection.views.form ? html`<button class="" onclick="${preview(doc.id)}">View</button>` : ''}
					<button class="" onclick="${edit(doc.id)}">Edit</button>
					<button class="" onclick="${copy(doc.id)}">Copy</button>
					<button class="" onclick="${archive(doc.id)}">${doc.archived ? 'Restore' : 'Archive'}</button>
				</div>
			</div>
		`;
	}

	function Rows(docs) {
		
		return html`
			${docs.map(doc => Row(doc))}
		`;
	}

	return html`

		${collection.views.default == 'table' ? html`
			<div class="table custom-scrollbar invisible-scrollbar table-header">
				${Header()}
			</div>
		` : ''}
		
		<div class="${collection.views.default} custom-scrollbar main-collection">
			<div class="masonry-wrapper">
				${Rows(docs)}
				${collection.views.default == 'masonry' ? html`
					<div class="row-sizer hide"></div>
				` : ''}
			</div>
			
		</div>

	`;

});

export default Collection_Table;