import {neverland as $, render, html, useState} from 'neverland';

const ForgotPasswordPage = $(function(auth, setAuth) {
	
	async function requestReset() {

		document.getElementById('error-generic').classList.add('hide');

		let email = document.getElementById('email').value;

		try {
			
			await auth.app.emailPasswordAuth.sendResetPasswordEmail(email);

			setAuth(prevAuth => {
				let auth = {...prevAuth};
				auth.publicPage = 'CheckEmail';
				return auth;
			})
		
		} catch(err) {

			console.warn(err);
			document.getElementById('error-generic').classList.remove('hide');
		
		}
	}

	function clickReset(event) {
		// Number 13 is the "Enter" key on the keyboard
		if (event.keyCode === 13) {
			event.preventDefault();
			document.getElementById("reset-button").click();
		}
	}

	function toLogin() {
		setAuth(prevAuth => {
			let auth = {...prevAuth};
			auth.publicPage = null;
			return auth;
		})
	}

	return html`
		<div class='login-fields'>

			<label>Email</label>
			<input id="email" type="text" onkeyup="${clickReset}" autofocus />

			<button class="forgot-password" onclick="${toLogin}">Back to log in</button>

			<button id="reset-button" onclick="${requestReset}">Email me a reset link</button>

			<div id="error-generic" class="login-error hide">User Not Found</div>

		</div>
	`;

});

export default ForgotPasswordPage;