import {neverland as $, render, html, useState, useEffect} from 'neverland';

import Select from './Select.js';

function isInteger(value) {
	return /^\d+$/.test(value);
}

function fixType(value) {
	if (value == "true") {
		return true;
	} else if (value == "false") {
		return false;
	} else if (isInteger(value)) {
		return Number(value);
	} else {
		return value;
	}
}

const SelectSubField = function(f, fields, idx, sf, doc, updateDocByFunction, collection, sequenceUpdateId, continuityId) {

	let subfields = fields[f].fields;

	function updateSubField(newVal) {
		
		if (subfields[sf].multiple) {
			newVal = newVal.map(v => fixType(v));
		} else {
			newVal = fixType(newVal);
		}

		updateDocByFunction(prevDoc => {
			let doc = {...prevDoc},
				seq = [...doc.curr[f]];
			seq[idx][sf] = newVal;
			if (subfields[sf].isReference) {
				if (newVal == '') {
					seq[idx].collection = null;
				} else {
					seq[idx].collection = subfields[sf].options.filter(d => d.value == newVal)[0].collection;
				}
			}
			doc.curr[f] = seq;
			return doc;	
		})
	}

	return html`
		<label>${subfields[sf].title}</label>
		${Select(
			subfields[sf].options,
			doc.curr[f][idx][sf],
			updateSubField,
			subfields[sf].groupByCollection,
			subfields[sf].optionCollections,
			subfields[sf].searchEnabled,
			subfields[sf].multiple,
			subfields[sf].hideOptions)}
	`;

	// 	${!subfields[sf].multiple ? html`<option value="">&nbsp;</option>` : ''}
};

export default SelectSubField;