import {neverland as $, render, html, useState, useEffect} from 'neverland';

import TextSubSubField from './Collection_Doc_TextSubSubField.js';
import SelectSubSubField from './Collection_Doc_SelectSubSubField.js';

function randomString() {
	return Math.random().toString().substr(2, 10);
}

const SequenceSubField = function(f, fields, idx, sf, doc, updateDocByFunction, getDefault, collection, sequenceUpdateId, setSequenceUpdateId) {

	let sbsbflds = Object.keys(fields[f].fields[sf].fields),
		subsubfields = fields[f].fields[sf].fields,
		f_idx = idx;

	function getDefaultElement() {
	// Generate a template element with default values for each subsubfield.

		let template = {};
		for (let ssf of sbsbflds) {
			template[ssf] = getDefault(ssf, subsubfields);
			if (subsubfields[ssf].isReference) {
				template['collection'] = '';
			}
		}
		template['continuityId'] = randomString();
		return template;
	}

	function insertAtIndex(idx) {
		return () => {
			setSequenceUpdateId(randomString());
			updateDocByFunction(prevDoc => {
				let doc = {...prevDoc};
				let seq = [...doc.curr[f][f_idx][sf]];
				seq.splice(idx, 0, getDefaultElement());
				doc.curr[f][f_idx][sf] = seq;
				// console.log(doc.curr[f]);
				return doc;	
			})
		}
	}

	function deleteByIndex(idx) {
		return () => {
			setSequenceUpdateId(randomString());
			updateDocByFunction(prevDoc => {
				let doc = {...prevDoc},
				seq = [...doc.curr[f][f_idx][sf]];
				seq.splice(idx, 1);
				doc.curr[f][f_idx][sf] = seq;
				// console.log(doc.curr[f]);
				return doc;	
			})
		}
	}

	function SubSubField(ssf, sf_idx, continuityId) {
		switch(subsubfields[ssf].type) {
			case 'text':
				return html`${TextSubSubField(f, fields, f_idx, sf, sf_idx, ssf, doc, updateDocByFunction)}`;
				break;
			case 'select':
				return html`${SelectSubSubField(f, fields, f_idx, sf, sf_idx, ssf, doc, updateDocByFunction, collection, sequenceUpdateId, continuityId)}`;
				break;
			default:
				break;
		}
	}

	function getFieldWidths(grp) {
		let ws = '';
		for (let ssf of sbsbflds) {
			ws = ws + `${subsubfields[ssf].w}% `
		}
		return ws;
	}

	function Element(d, idx) {
		return html`
			<div class="item">
				<div class="item-content">
					
					<div class="content" style="grid-template-columns: ${getFieldWidths()};">
						${sbsbflds.map(ssf => html`
							<div
								class="subsubfield-box subsubfield-box-${ssf}"
								style="grid-column: auto / span ${subsubfields[ssf].w_in_ventiles}">
								${SubSubField(ssf, idx, d.continuityId)}
							</div>`)}
					</div>
					
					<div class="buttons">	
						<div
							class="button delete"
							onclick="${deleteByIndex(idx)}">&#215;</div>
					</div>

				</div>
			</div>
		`;
	}

	// console.log(doc.curr[f][idx])

	return html`
		<div>
			${doc.curr[f][idx][sf].map((d, idx) => Element(d, idx))}
			<div
				class="insert"
				onclick="${insertAtIndex(doc.curr[f][idx][sf].length)}">+</div>
		</div>
		<label>${fields[f].fields[sf].title}</label>
	`;

};

export default SequenceSubField;