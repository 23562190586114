import {neverland as $, render, html, useState} from 'neverland';

const app = new Realm.App({
	id: "prod-sydney-fglkd",
	timeout: 10000
 });

import Router from './Router.js'

const App = $(function() {
	
	let publicPage = (new URLSearchParams(window.location.search)).get('token') !== null ? 'ResetPassword' : null;

	let page = (new URLSearchParams(window.location.search)).get('p');

	const [auth, setAuth] = useState(
		{
			app: app,
			isLoggedIn: (app.currentUser !== null),
			db: app.currentUser !== null ? app.currentUser.mongoClient("mongodb-atlas").db("prod") : null,
			
			publicPage: publicPage,
			page: page
		}
	);
	
	return html`
		${Router(auth, setAuth)}
	`;

});

export default App;

// THOUGHTS

// - Use MongoDB users for admin app.
// - Use Custom Function authentication for reporting portal users.