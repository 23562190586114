import {neverland as $, render, html} from 'neverland';

function isInteger(value) {
	return /^\d+$/.test(value);
}

const TextSubField = function(f, fields, idx, sf, doc, updateDocByFunction) {
	
	function updateSubField(ev) {
		updateDocByFunction(prevDoc => {
			let doc = {...prevDoc},
				seq = [...doc.curr[f]];

			// Convert value to number if an integer.
			let newVal = ev.target.value;
			if (isInteger(newVal)) {
				newVal = Number(newVal);
			}

			seq[idx][sf] = newVal;
			doc.curr[f] = seq;
			return doc;	
		});
	}

	// return html`<input type="text">${sf}</input>`;

	return html`
		<label>${fields[f].fields[sf].title}</label>
		<input
			class="subfield-${f}"
			type="text"
			placeholder="${fields[f].fields[sf].title}"
			value="${doc.curr[f][idx][sf]}"
			oninput="${updateSubField}"></input>
	`;

};

// const TextSubField = function() {
// 	return html`<input type="text"></input>`;
// }

export default TextSubField;