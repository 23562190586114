import {countries} from "./countries";
function generatePassword() {
					
	let rval = '',
		chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
		n = chars.length;
	
	for (let i = 0; i < 6; i++) {
		rval += chars.charAt(Math.floor(Math.random() * n));
	}

	return rval;
	
}

function generateAccessCode() {
					
	function generate(n) {
		var add = 1, max = 12 - add;

		if ( n > max ) {
			return generate(max) + generate(n - max);
		}

		max = Math.pow(10, n+add);
		var min = max/10; // Math.pow(10, n) basically
		var number = Math.floor( Math.random() * (max - min + 1) ) + min;

		return ("" + number).substring(add); 
	}
	
	return generate(6);
	
}

const collections = {
	ClientGroups: {
		title: 'Client Groups',
		nounSingular: 'Client Group',
		collectionName: 'ClientGroups',
		humanReferenceField: 'name',
		fields: {
			name: {
				title: 'Name',
				type: 'text',
				required: true,
				filterable: true
			},
			type: {
				title: 'Type',
				type: 'select',
				required: true,
				options: [
					{
						value: 'school',
						label: 'School'
					},
					{
						value: 'LGA',
						label: 'Local Government Area'
					},
					{
						value: 'campus',
						label: 'Campus'
					},
					{
						value: 'company',
						label: 'Company'
					},
					{
						value: 'other',
						label: 'Other'
					}
				],
				default: 'school'
			},
			sector: {
				title: 'Sector',
				type: 'select',
				required: false,
				options: [
					{
						value: 'GOV',
						label: 'Government'
					},
					{
						value: 'IND',
						label: 'Independent'
					},
					{
						value: 'CAT',
						label: 'Catholic'
					},
					{
						value: 'ALT',
						label: 'Alternative'
					},
				],
				onlyShowIf: {
					field: 'type',
					values: ['school']
				},
			},
			gender: {
				title: 'Single Sex Status',
				type: 'select',
				required: false,
				options: [
					{
						value: 'C',
						label: 'Co-ed'
					},
					{
						value: 'F',
						label: 'Girls'
					},
					{
						value: 'M',
						label: 'Boys'
					}
				],
				default: 'C',
				onlyShowIf: {
					field: 'type',
					values: ['school']
				},
			},
			username: {
				title: 'Username',
				type: 'text',
				required: false,
				classes: ['monospace'],
				filterable: true
			},
			password: {
				title: 'Password',
				type: 'text',
				required: false,
				classes: ['monospace'],
				default: generatePassword,
				filterable: true
			},
			contacts: {
				title: 'Contacts',
				type: 'sequence',
				required: false,
				fields: {
					first: {
						title: 'First',
						type: 'text',
						required: false,
						w: 35
					},
					last: {
						title: 'Last',
						type: 'text',
						required: false,
						w: 35
					},
					position: {
						title: 'Position',
						type: 'text',
						required: false,
						w: 30
					},
					email: {
						title: 'Email Address',
						type: 'text',
						required: false,
						w_in_ventiles: 20,
						UIgroup: 'contact'
					},
					mobile: {
						title: 'Mobile Number',
						type: 'text',
						required: false,
						w_in_ventiles: 10,
						UIgroup: 'contact'
					},
					landline: {
						title: 'Landline Number',
						type: 'text',
						required: false,
						w_in_ventiles: 10,
						UIgroup: 'contact'
					},
				},
				UIgroups: {
					'contact': {
						icon: '&#128222;'
					}
				}
			},
			street: {
				title: 'Street Address',
				type: 'text',
				required: false,
			},
			suburb: {
				title: 'Suburb',
				type: 'text',
				required: false,
			},
			state: {
				title: 'State',
				type: 'select',
				required: false,
				options: [
					{
						value: 'ACT',
						label: 'Australian Capital Territory'
					},
					{
						value: 'NSW',
						label: 'New South Wales'
					},
					{
						value: 'NT',
						label: 'Northern Territory'
					},
					{
						value: 'QLD',
						label: 'Queensland'
					},
					{
						value: 'SA',
						label: 'South Australia'
					},
					{
						value: 'TAS',
						label: 'Tasmania'
					},
					{
						value: 'VIC',
						label: 'Victoria'
					},
					{
						value: 'WA',
						label: 'Western Australia'
					},
				],
				searchEnabled: true
			},
			postcode: {
				title: 'Postcode',
				type: 'text',
				required: false,
			},
			country: {
				title: 'Country',
				type: 'select',
				required: true,
				searchEnabled: true,
				options: countries.map(c => ({label: c.name, value: c.name})),
				default: 'Australia'
			},
			permissions: {
				title: 'Permissions',
				type: 'select',
				required: false,
				multiple: true,
				options: [
					{
						value: 'surveyStudentResilience',
						label: '(portal) Resilience Survey'
					},
					{
						value: 'riskyBehaviours',
						label: '(portal) Risky Behaviours'
					},
					{
						value: 'betaFeatures',
						label: '(portal) Beta Features'
					},
					{
						value: 'showClusterSurveys',
						label: '(portal) Show Cluster Surveys'
					},
					{
						value: 'enableSurveySelector',
						label: '(portal) Enable Survey Selector'
					},
					{
						value: 'editableCloseDates',
						label: '(portal) Make survey close dates editable'
					},
					{
						value: 'showGemfinder',
						label: '(portal) Show Gemfinder'
					},
					{
						value: 'resilientYouthStaff',
						label: '(portal) Resilient Youth Staff Features'
					},
					{
						value: 'theResilienceProject',
						label: '(portal) The Resilience Project'
					},
					{
						value: 'surveyStudentResilienceShort',
						label: '(portal) Short Resilience Survey'
					},
					{
						value: 'surveyHybrid',
						label: '(portal) Hybrid Survey'
					},
					{
						value: 'surveySchoolStaff',
						label: '(portal) Staff Survey'
					},
					{
						value: 'surveyNoBullying',
						label: '(portal) No Bullying Survey'
					},
					{
						value: 'noMinimumNumber',
						label: '(portal) Turn off minimum numbers check'
					},
					{
						value: 'surveyWorkplaceResilience',
						label: '(portal) Workplace Resilience Survey'
					},
					{
						value: 'surveyParent',
						label: '(portal) Parent and Caregiver Survey'
					},
					{
						value: 'showHomepageFilters',
						label: '(portal) Show homepage filter options'
					},
					{
						value: 'reportDemographic',
						label: 'Demographic Report'
					},
					{
						value: 'surveyIPE_Mi7',
						label: '(portal) IPE-Mi7 Survey'
					},
					{
						value: 'IPEmoduleRiskyBehaviours',
						label: 'IPE-module: Risky Behaviours'
					},
					{
						value: 'IPEmoduleBoardingHouse',
						label: 'IPE-module: Boarding House'
					},
					{
						value: 'IPEmoduleCharacterStrengths',
						label: 'IPE-module: Character Strengths'
					},
					{
						value: 'surveyJGI_NatureWellbeingLower',
						label: 'JGI-survey: Nature Wellbeing | Lower Primary'
					},
					{
						value: 'surveyJGI_NatureWellbeingUpper',
						label: 'JGI-survey: Nature Wellbeing | Upper Primary'
					},
					{
						value: 'surveyJGI_NatureWellbeingStaff',
						label: 'JGI-survey: Nature Wellbeing | Staff'
					},
					{
						value: 'surveyLearningCurveWellbeing',
						label: 'LearningCurve-survey: Wellbeing'
					},
					{
						value: 'IPEmoduleAdaptability',
						label: 'IPE-module: Adaptability'
					},
					{
						value: 'surveyKindSchools',
						label: '(portal) Kindness Survey'
					},
					{
						value: 'surveyPhillipsFoundation',
						label: '(portal) TOMS Survey'
					},
					{
						value: 'surveySWAT',
						label: '(portal) School Wellbeing Audit Tool Survey'
					},
					{
						value: 'cultureBackgroundFilter',
						label: '(portal) Turn on interactive cultural background filter'
					},
					{
						value: 'enableActionPlans',
						label: '(portal) Turn on Plans tab for Action Plans'
					},
				],
				default: [],
			}
		},
		views: {
			default: 'table',
			table: [
				{ key: 'name', w: 25},
				{ key: 'username', w: 25},
				{ key: 'password', w: 10},
				{ key: 'state', w: 20},
				{ key: 'country', w: 20}
			],
			form: false
		},
		onCopy: {}
	},
	Items: {
		title: 'Items',
		nounSingular: 'Item',
		collectionName: 'Items',
		humanReferenceField: 'key',
		fields: {
			type: {
				title: 'Type',
				type: 'select',
				// multiple: true,
				required: true,
				options: [
					{
						value: 'choose-1',
						label: 'Select One'
					},
					{
						value: 'choose-n',
						label: 'Select Multiple'
					},
					{
						value: 'dropdown-1',
						label: 'Dropdown'
					},
					{
						value: 'text',
						label: 'Text'
					},
					{
						value: 'text-area',
						label: 'Text Area'
					},
					{
						value: 'number',
						label: 'Number'
					},
					{
						value: 'paragraph',
						label: 'Explanation'
					}
				],
				default: 'choose-1',
				isCore: true
			},
			key: {
				title: 'Key',
				type: 'text',
				required: true,
				classes: ['monospace'],
				isCore: false,
				filterable: true
			},
			prompt: {
				title: 'Prompt',
				type: 'textarea',
				required: true,
				isCore: true,
				filterable: true
			},
			caption: {
				title: 'Caption',
				type: 'text',
				required: false,
				isCore: true
			},
			description: {
				title: 'Description',
				type: 'text',
				required: false,
				isCore: true
			},
			explanation: {
				title: 'Fine Print',
				type: 'text',
				required: false,
				onlyShowIf: {
					field: 'type',
					values: ['paragraph']
				},
				isCore: true
			},
			placeholder: {
				title: 'Placeholder',
				type: 'text',
				required: false,
				onlyShowIf: {
					field: 'type',
					values: ['dropdown-1']
				},
				isCore: true
			},
			imageURL: {
				title: 'Image URL',
				type: 'text',
				required: false,
				onlyShowIf: {
					field: 'type',
					values: ['paragraph', 'choose-1']
				},
				isCore: true
			},
			options: {
				title: 'Options',
				type: 'sequence',
				required: false,
				fields: {
					label: {
						title: 'TEXT',
						type: 'text',
						required: true,
						w: 85
					},
					value: {
						title: 'CODE',
						type: 'text',
						required: true,
						w: 15
					}
				},
				onlyShowIf: {
					field: 'type',
					values: ['choose-1', 'choose-n', 'dropdown-1']
				},
				isCore: true
			},
			precision: {
				title: 'How many decimal places?',
				type: 'text',
				required: false,
				onlyShowIf: {
					field: 'type',
					values: ['number']
				},
				isCore: true
			},
			version: {
				title: 'Version',
				type: 'text',
				required: true,
				default: 'main',
				isCore: false,
				filterable: true
			},
			versionNotes: {
				title: 'Version Notes',
				type: 'textarea',
				required: false,
				isCore: false
			},
			language: {
				title: 'Language',
				type: 'select',
				options: [
					// Language codes use the IETF language tag standard, where possible.
					{
						value: 'en-AU',
						label: 'English (Australia)'
					},
					{
						value: 'cmn',
						label: 'Simplified Chinese (Mandarin)'
					},
					{
						value: 'yue',
						label: 'Traditional Chinese (Cantonese)'
					}
				],
				default: 'en-AU',
				isCore: false
			},
			testRetestEligibility: {
				title: 'Eligibility for Test-Retest Data Collection',
				type: 'select',
				options: [
					{
						value: 1,
						label: 'Eligible'
					},
					{
						value: 0,
						label: 'Not Eligible'
					}
				],
				default: 0,
				required: true,
				isCore: false
			},
			hasResponses: {
				title: 'Has Responses',
				type: 'checkbox',
				default: false,
				editable: false,
				isCore: false
			}
		},
		views: {
			default: 'masonry',
			table: [
				{ key: 'key', w: 10},
				{ key: 'type', w: 10},
				{ key: 'prompt', w: 80}
			],
			masonry: [
				{ key: 'prompt'},
				{ key: 'key'},
				{ key: 'type'},
				{ key: 'version'}
			],
			form: true
		},
		onCopy: {
			change: function(doc) {
				
				// Set hasResponses to false.
				doc.hasResponses = false;
				
				return doc;
			},
			// check: function(doc) {

			// 	let messages = [];

			// 	if (doc.prev.version == doc.curr.version) {
			// 		messages.push({
			// 			type: 'warning',
			// 			content: 'You need to change the version description so you can tell the new version from the old version.'
			// 		})
			// 	}

			// 	return messages;
			// }
		}

	},
	ItemSets: {
		title: 'Item Sets',
		nounSingular: 'Item Set',
		collectionName: 'ItemSets',
		humanReferenceField: 'name',
		refCollectionSpecs: [
			{
				name: 'Items',
				labelFormat: [
					{ type: 'field', value: 'key' },
					{ type: 'text',	 value: ' (', if: 'version'},
					{ type: 'field', value: 'version', if: 'version' },
					{ type: 'text',  value: ')', if: 'version' },
					{ type: 'text',  value: ' - ' },
					{ type: 'field', value: 'prompt' },
				],
				storeFullCollection: true
			},
			{
				name: 'ItemSets',
				labelFormat: [
					{ type: 'field', value: 'name' },
					{ type: 'text',	 value: ' (', if: 'version'},
					{ type: 'field', value: 'version', if: 'version' },
					{ type: 'text',  value: ')', if: 'version' }
				],
				storeFullCollection: true
			}
		],
		fields: {
			name: {
				title: 'Name',
				type: 'text',
				required: true,
				isCore: false
			},
			elements: {
				title: 'Elements',
				type: 'sequence',
				required: true,
				fields: {
					element: {
						title: 'Element',
						type: 'select',
						required: true,
						isReference: true,
						w: 100,
						searchEnabled: true,
						groupByCollection: true,
						options: ['Items', 'ItemSets'],
						hideOptions: true
					},
					required: {
						title: 'Required',
						type: 'select',
						options: [
							{
								value: true,
								label: 'This item is REQUIRED'
							},
							{
								value: false,
								label: 'This item is OPTIONAL'
							}
						],
						default: true,
						w_in_ventiles: 20,
						UIgroup: 'required'
					},
					conditionAggregator: {
						title: 'Show...',
						type: 'select',
						options: [
							// {
							// 	value: 'each',
							// 	label: 'Show for EACH of these conditions'
							// },
							{
								value: 'one',
								label: 'Only show if at least ONE of these conditions holds'
							},
							{
								value: 'all',
								label: 'Only show if ALL of these conditions hold'
							}
						],
						w_in_ventiles: 20,
						UIgroup: 'logic'
					},
					conditions: {
						title: 'Conditions',
						type: 'sequence',
						required: false,
						UIgroup: 'logic',
						w_in_ventiles: 20,
						fields: {
							item: {
								title: 'Item',
								type: 'select',
								required: false,
								isReference: true,
								options: ['Items'],
								searchEnabled: true,
								w_in_ventiles: 10
							},
							condition: {
								title: 'Condition',
								type: 'select',
								options: [
									{
										value: '=',
										label: 'IS'
									},
									// {
									// 	value: '!=',
									// 	label: 'IS NOT'
									// }
								],
								required: false,
								w_in_ventiles: 6
							},
							value: {
								title: 'Value',
								type: 'select',
								options: [],
								required: false,
								w_in_ventiles: 4
							}
						}
					}
				},
				UIgroups: {
					'required': {
						icon: '&#10043;'
					},
					'logic': {
						icon: '&#9283;'
					}
				},
				isCore: true
			},
			rotators: {
				title: 'Rotators',
				type: 'sequence',
				required: false,
				fields: {
					element: {
						title: 'Item Set',
						type: 'select',
						required: true,
						isReference: true,
						w: 80,
						searchEnabled: true,
						options: ['ItemSets'],
						hideOptions: true
					},
					n: {
						title: '# to show',
						type: 'text',
						required: true,
						w: 20,
						default: 1
					}
				}
			},
			version: {
				title: 'Version',
				type: 'text',
				required: false,
				isCore: false
			},
			versionNotes: {
				title: 'Version Notes',
				type: 'textarea',
				required: false,
				isCore: false
			},
			hasResponses: {
				title: 'Has Responses',
				type: 'checkbox',
				default: false,
				editable: false,
				isCore: false
			}
		},
		views: {
			default: 'table',
			table: [
				{ key: 'name', w: 50},
				{ key: 'version', w: 50}
			],
			form: true
		},
		onCopy: {
			change: function(doc) {
				
				// Set hasResponses to false.
				doc.hasResponses = false;
				
				return doc;
			}
		}
	},
	Surveys: {
		title: 'Surveys',
		nounSingular: 'Survey',
		collectionName: 'Surveys',
		humanReferenceField: 'name',
		refCollectionSpecs: [
			{
				name: 'ClientGroups',
				labelFormat: [
					{ type: 'field', value: 'name' }
				]
			},
			{
				name: 'ItemSets',
				labelFormat: [
					{ type: 'field', value: 'name' },
					{ type: 'text',	 value: ' (', if: 'version'},
					{ type: 'field', value: 'version', if: 'version' },
					{ type: 'text',  value: ')', if: 'version' }
				]
			},
			{
				name: 'EndScreens',
				labelFormat: [
					{ type: 'field', value: 'name' },
					{ type: 'text',	 value: ' (' },
					{ type: 'field', value: 'language' },
					{ type: 'text',  value: ')' }
				]
			}
		],
		fields: {
			type: {
				title: 'Type',
				type: 'select',
				options: [
					{
						value: 'test',
						label: 'Internal Testing Survey'
					},
					{
						value: 'demo',
						label: 'Demo or Example Survey'
					},
					{
						value: 'real',
						label: 'Client Survey'
					}
				],
				default: 'real'
			},
			name: {
				title: 'Name',
				type: 'text',
				default: 'Student Resilience Survey',
				required: true
			},
			itemSet: {
				title: 'Item Set',
				type: 'select',
				required: true,
				isReference: true,
				searchEnabled: true,
				options: ['ItemSets']
			},
			dataClientGroup: {
				title: 'Data Client Group',
				type: 'select',
				multiple: false,
				required: true,
				isReference: true,
				searchEnabled: true,
				options: ['ClientGroups']
			},
			accessClientGroups: {
				title: 'Access Client Groups',
				type: 'select',
				multiple: true,
				required: true,
				isReference: true,
				searchEnabled: true,
				options: ['ClientGroups']
			},
			accessCode: {
				title: 'Access Code',
				type: 'text',
				required: false,
				filterable: true,
				default: generateAccessCode
			},
			openDate: {
				title: 'Open Date',
				type: 'date',
				required: true
			},
			closeDate: {
				title: 'Close Date',
				type: 'date',
				required: true
			},
			includeTestRetest: {
				title: 'Include Test-Retest Section',
				type: 'select',
				options: [
					{
						value: 1,
						label: 'Include 1 item'
					},
					{
						value: 0,
						label: "Don't include"
					}
				],
				default: 1,
				required: true,
				isCore: false
			},
			endScreen: {
				title: 'End Screen',
				type: 'select',
				required: true,
				isReference: true,
				searchEnabled: true,
				options: ['EndScreens']
			},
		},
		views: {
			default: 'table',
			table: [
				{ key: 'dataClientGroup', w: 30},
				{ key: 'itemSet', w: 30},
				{ key: 'accessCode', w: 20},
				{ key: 'closeDate', w: 20},
			],
			form: false
		},
		onCopy: {
			change: function(doc) {
			
				// Generate new access code.
				doc.accessCode = generateAccessCode();
				
				return doc;
			}
		}
	},
	Responses: {
		title: 'Responses',
		nounSingular: 'Response',
		collectionName: 'Responses',
		humanReferenceField: 'survey',
		refCollectionSpecs: [
			{
				name: 'Items',
				labelFormat: [
					{ type: 'field', value: 'key' },
					{ type: 'text',	 value: ' (', if: 'version'},
					{ type: 'field', value: 'version', if: 'version' },
					{ type: 'text',  value: ')', if: 'version' },
					{ type: 'text',  value: ' - ' },
					{ type: 'field', value: 'prompt' },
				]
			},
			{
				name: 'Surveys',
				labelFormat: [
					{ type: 'field', value: 'name' }
				]
			},
			{
				name: 'ItemSets',
				labelFormat: [
					{ type: 'field', value: 'name' },
					{ type: 'text',	 value: ' (', if: 'version'},
					{ type: 'field', value: 'version', if: 'version' },
					{ type: 'text',  value: ')', if: 'version' }
				]
			}
		],
		fields: {
			survey: {
				title: 'Survey',
				type: 'select',
				required: true,
				isReference: true,
				searchEnabled: true,
				options: ['Surveys']
			},
			itemSet: {
				title: 'Item Set',
				type: 'select',
				required: true,
				isReference: true,
				searchEnabled: true,
				options: ['ItemSets']
			},
			timestamp: {
				title: 'Timestamp',
				type: 'text',
				required: true
			},
			answers: {
				title: 'Answers',
				type: 'sequence',
				required: false,
				fields: {
					item: {
						title: 'Item',
						type: 'select',
						required: true,
						isReference: true,
						searchEnabled: true,
						options: ['Items'],
						hideOptions: true,
						w: 55
					},
					answer: {
						title: 'Answer',
						type: 'text',
						required: true,
						w: 45
					}
				}
			},
			testRetestItems: {
				title: 'Test-Retest Items',
				type: 'select',
				required: false,
				multiple: true,
				isReference: true,
				searchEnabled: true,
				options: ['Items'],
				hideOptions: true
			},
			rotatorItems: {
				title: 'Rotator Items',
				type: 'select',
				required: false,
				multiple: true,
				isReference: true,
				searchEnabled: true,
				options: ['Items'],
				hideOptions: true
			},
		},
		views: {
			default: 'table',
			table: [
				{ key: 'survey', w: 30},
				{ key: 'timestamp', w: 70}
			],
			form: false
		},
		onCopy: {}
	},
	Themes: {
		title: 'Themes',
		nounSingular: 'Theme',
		collectionName: 'Themes',
		humanReferenceField: 'name',
		refCollectionSpecs: [
			{
				name: 'ClientGroups',
				labelFormat: [
					{ type: 'field', value: 'name' }
				]
			}
		],
		fields: {
			name: {
				title: 'Name',
				type: 'text',
				required: true,
			},
			clientGroup: {
				title: 'Associated Client Group',
				type: 'select',
				multiple: false,
				required: true,
				isReference: true,
				searchEnabled: true,
				options: ['ClientGroups']
			},
			domains: {
				title: 'Domains',
				type: 'sequence',
				required: false,
				fields: {
					hostname: {
						title: 'Hostname',
						type: 'text',
						required: true,
						w: 100
					}
				}
			},
			logo: {
				title: 'Logo',
				type: 'text'
			},
			logoUrl: {
				title: 'Logo Click Url',
				type: 'text'
			},
			logoLarge: {
				title: 'Large Logo (base64 encoded)',
				type: 'textarea'
			},
			logoSmall: {
				title: 'Small Logo (base64 encoded)',
				type: 'textarea'
			},
			favicon: {
				title: 'Favicon',
				type: 'text'
			},
			sansSerifStack: {
				title: 'Sans-Serif Font Stack',
				type: 'text'
			},
			serifStack: {
				title: 'Serif Font Stack',
				type: 'text'
			},
			pdfFonts: {
				title: 'PDF Fonts',
				type: 'sequence',
				fields: {
					family: {
						title: 'Family',
						type: 'text',
						required: true,
						w: 30
					},
					variant: {
						title: 'Variant',
						type: 'text',
						required: true,
						w: 30
					},
					file: {
						title: 'Font File',
						type: 'text',
						required: true,
						w: 40	
					}
				}
			},
			monospaceStack: {
				title: 'Monospace Font Stack',
				type: 'text'
			},
			colourMain: {
				title: 'Colour: Main',
				type: 'colour'
			},
			colourMainTranslucent: {
				title: 'Colour: Main Translucent',
				type: 'text'
			},
			colourMainTint: {
				title: 'Colour: Main Tint',
				type: 'colour'
			},
			colourLoginInput: {
				title: 'Colour: Login Input',
				type: 'colour'
			},
			colourLoginError: {
				title: 'Colour: Login Error',
				type: 'colour'
			},
			colourProgress: {
				title: 'Colour: Survey Progress Bar',
				type: 'text'
			},
			imageBackground: {
				title: 'Background Image',
				type: 'text'
			},
			backgroundSize: {
				title: 'Background Size',
				type: 'text'
			},
			backgroundRepeat: {
				title: 'Background Repeat',
				type: 'text'
			},
			backgroundPosition: {
				title: 'Background Position',
				type: 'text'
			},
			offeringBlockDisplay: {
				title: 'Offering Block Display',
				type: 'text'
			},
			customCss: {
				title: 'Custom CSS (Survey Portal)',
				type: 'textarea'
			},
			customCssReporting: {
				title: 'Custom CSS (Reporting Portal)',
				type: 'textarea'
			},
			copyrightSurvey: {
				title: 'Copyright Survey',
				type: 'textarea'
			},
			// Report Portal Details
			copyright: {
				title: 'Copyright',
				type: 'textarea'
			},
			copyrightPdf: {
				title: 'Copyright PDF',
				type: 'textarea'
			},
			providerName: {
				title: 'Provider Name',
				type: 'text',
				required: true
			},
			dashboardLinksTitle: {
				title: 'Dashboard Links Title',
				type: 'text',
				required: false
			},
			dashboardLinks: {
				title: 'Dashboard Links',
				type: 'sequence',
				required: false,
				fields: {
					icon: {
						title: 'Icon',
						type: 'text',
						required: false,
						w: 20
					},
					text: {
						title: 'Text',
						type: 'text',
						required: false,
						w: 40
					},
					url: {
						title: 'URL',
						type: 'text',
						required: false,
						w: 40	
					}
				}
			},
			dashboardText: {
				title: 'Dashboard Text',
				type: 'textarea',
				required: false
			},
			userSupportEmail: {
				title: 'User Support Email',
				type: 'text',
				required: false
			},
			userSupportPhone: {
				title: 'User Support Phone Number',
				type: 'text',
				required: false
			},
			userSupportName: {
				title: 'User Support Name',
				type: 'text',
				required: false
			}
		},
		views: {
			default: 'table',
			table: [
				{ key: 'name', w: 100}
			],
			form: false
		},
		onCopy: {}
	},
	EndScreens: {
		title: 'End Screens',
		nounSingular: 'End Screen',
		collectionName: 'EndScreens',
		humanReferenceField: 'name',
		fields: {
			name: {
				title: 'Name',
				type: 'text',
				required: true,
			},
			title: {
				title: 'Title',
				type: 'text'
			},
			subtitle: {
				title: 'Subtitle',
				type: 'text'
			},
			content: {
				title: 'Content',
				type: 'textarea'
			},
			language: {
				title: 'Language',
				type: 'select',
				options: [
					// Language codes use the IETF language tag standard, where possible.
					{
						value: 'en-AU',
						label: 'English (Australia)'
					},
					{
						value: 'cmn',
						label: 'Simplified Chinese (Mandarin)'
					},
					{
						value: 'yue',
						label: 'Traditional Chinese (Cantonese)'
					}
				],
				default: 'en-AU'
			},
			website: {
				title: 'Website',
				type: 'text'
			}
		},
		views: {
			default: 'table',
			table: [
				{ key: 'name', w: 60},
				{ key: 'language', w: 40}
			],
			form: false
		},
		onCopy: {}
	}
};

export default collections;