import {neverland as $, render, html, useState, useEffect} from 'neverland';

const CheckboxField = $(function(f, fields, doc, updateDoc) {
	
	function updateField(ev) {
		updateDoc(f, ev.target.checked);
	}

	let editable = true;
	if (fields[f].hasOwnProperty('editable')) {
		editable = fields[f].editable;
	}

	return html`
		<div class="switch-container">
			<input 
				class="field-${f} switch"
				type="checkbox"
				checked="${doc.curr[f]}"
				onchange="${updateField}"
				disabled="${!editable}"></input>
		</div>
		<label>${fields[f].title}</label>
	`;

});

export default CheckboxField;