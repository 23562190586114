import {neverland as $, render, html, useState} from 'neverland';

import Menu from './Menu.js';
import LoginPage from './LoginPage.js';
import ForgotPasswordPage from './ForgotPasswordPage.js';
import CheckEmailPage from './CheckEmailPage.js';
import ResetPasswordPage from './ResetPasswordPage.js';
import ResetSuccessPage from './ResetSuccessPage.js';

import Collection from './Collection.js';
import collections from '../specs/collections.js';

const Router = $(function(auth, setAuth) {
	
	if (auth.publicPage === null) {

		switch (auth.isLoggedIn) {
			case true:
				switch (auth.page) {
					case 'ClientGroups':
					case 'Items':
					case 'ItemSets':
					case 'Surveys':
					case 'Responses':
					case 'Themes':
					case 'EndScreens':
						return html`
							${Menu(auth, setAuth)}
							${Collection(auth, collections[auth.page])}
						`;
						break;
					default:
						return html`
							${Menu(auth, setAuth)}
						`;
						break;
				}
				break;
			default:
				return html`
					${LoginPage(auth, setAuth)}
				`;
				break;
		}


	} else {

		switch (auth.publicPage) {
			case 'ForgotPassword':
				return html`
					${ForgotPasswordPage(auth, setAuth)}
				`;
				break;
			case 'CheckEmail':
				return html`
					${CheckEmailPage()}
				`;
				break;
			case 'ResetPassword':
				return html`
					${ResetPasswordPage(auth, setAuth)}
				`;
				break;
			case 'ResetSuccess':
				return html`
					${ResetSuccessPage(setAuth)}
				`;
				break;
		}

	}



});

export default Router;

// THOUGHTS

// - Use MongoDB users for admin app.
// - Use Custom Function authentication for reporting portal users.