import {neverland as $, render, html, useState, useEffect} from 'neverland';

function isInteger(value) {
	return /^\d+$/.test(value);
}

const TextField = $(function(f, fields, doc, updateDoc) {
	
	function updateField(ev) {

		// Convert value to number if an integer.
		let newVal = ev.target.value;
		if (isInteger(newVal) & f != "accessCode") {
			newVal = Number(newVal);
		}

		updateDoc(f, newVal);
	}

	function guessUsername() {

		let username = doc.curr.name
				.replace(/[.,'"\/#!$%\^&\*;:{}=\-_`~()]/g,"")
				.split(' ')
				.join('');

		updateDoc('username', username)

	}

	function generatePassword() {
					
		let rval = '',
			chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
			n = chars.length;
		
		for (let i = 0; i < 6; i++) {
			rval += chars.charAt(Math.floor(Math.random() * n));
		}

		updateDoc('password', rval);
		
	}

	return html`
		<input
			class="field-${f}"
			type="text"
			value="${doc.curr[f]}"
			oninput="${updateField}"></input>
		<label>
			${fields[f].title}
			${f == 'username' ? html`(<button class="inline" onclick="${guessUsername}">Guess</button>)` : ''}
			${f == 'password' ? html`(<button class="inline" onclick="${generatePassword}">Generate</button>)` : ''}
		</label>
	`;

});

export default TextField;