import {neverland as $, render, html, useState, useEffect} from 'neverland';
import Select from './Select.js';

function isInteger(value) {
	return /^\d+$/.test(value);
}

function fixType(value) {
	if (value == "true") {
		return true;
	} else if (value == "false") {
		return false;
	} else if (isInteger(value)) {
		return Number(value);
	} else {
		return value;
	}
}

const SelectField = $(function(f, fields, doc, updateDoc, collection) {

	function updateField(newVal) {

		if (fields[f].multiple) {
			newVal = newVal.map(v => fixType(v));
		} else {
			newVal = fixType(newVal);
		}

		updateDoc(f, newVal);
	}

	return html`
		${Select(
			fields[f].options,
			doc.curr[f],
			updateField,
			fields[f].groupByCollection,
			fields[f].optionCollections,
			fields[f].searchEnabled,
			fields[f].multiple,
			fields[f].hideOptions)}
		<label>${fields[f].title}</label>
	`;

});

export default SelectField;