import {neverland as $, render, html} from 'neverland';

function isInteger(value) {
	return /^\d+$/.test(value);
}

const TextSubSubField = function(f, fields, f_idx, sf, sf_idx, ssf, doc, updateDocByFunction) {
	
	function updateSubSubField(ev) {
		updateDocByFunction(prevDoc => {
			let doc = {...prevDoc},
				seq = [...doc.curr[f][f_idx][sf]];

			// Convert value to number if an integer.
			let newVal = ev.target.value;
			if (isInteger(newVal)) {
				newVal = Number(newVal);
			}

			seq[sf_idx][ssf] = newVal;
			doc.curr[f][f_idx][ssf] = seq;
			return doc;	
		});
	}

	return html`
		<label>${fields[f].fields[sf].fields[ssf].title}</label>
		<input
			class="subsubfield-${f}"
			type="text"
			placeholder="${fields[f].fields[sf].fields[ssf].title}"
			value="${doc.curr[f][f_idx][sf][sf_idx][ssf]}"
			oninput="${updateSubSubField}"></input>
	`;

};

export default TextSubSubField;