import {neverland as $, render, html, useState} from 'neverland';

function assert(condition, message) {
	if (!condition) {
		throw new Error(message || "Assertion failed");
	}
}

const LoginPage = $(function(auth, setAuth) {
	
	async function login() {

		document.getElementById('error-generic').classList.add('hide');

		let email    = document.getElementById('email').value,
			password = document.getElementById('password').value;

		// Create credentials.
		const credentials = Realm.Credentials.emailPassword(email, password);

		try {
			
			// Authenticate the user
			const user = await auth.app.logIn(credentials);
			
			// `App.currentUser` updates to match the logged in user
			assert(user.id === auth.app.currentUser.id)

			setAuth(prevAuth => {
				let auth = {...prevAuth};
				auth.isLoggedIn = true;
				return auth;
			})

			return user;
		
		} catch(err) {

			console.warn(err);
			document.getElementById('error-generic').classList.remove('hide');
		
		}
	}

	function clickLogin(event) {
		// Number 13 is the "Enter" key on the keyboard
		if (event.keyCode === 13) {
			event.preventDefault();
			document.getElementById("login-button").click();
		}
	}

	function forgotPassword() {
		setAuth(prevAuth => {
			let auth = {...prevAuth};
			auth.publicPage = 'ForgotPassword';
			return auth;
		})
	}

	return html`
		<div class='login-fields'>

			<label>Email</label>
			<input id="email" type="text" autofocus />

			<label>Password</label>
			<input id="password" type="password" onkeyup="${clickLogin}" />
			
			<button class="forgot-password" onclick="${forgotPassword}">Forgot password</button>

			<button id="login-button" onclick="${login}">Log In</button>


			<div id="error-generic" class="login-error hide">Login failed</div>

		</div>
	`;

});

export default LoginPage;