import {neverland as $, render, html, useState, useEffect} from 'neverland';

const TextInput = $(function(value, updateValue) {

	function update(ev) {
		let newVal = ev.target.value;
		updateValue(newVal);
	}

	return html`<input
		type="text"
		value="${value}"
		oninput=${update}
	></input>`;

})

export default TextInput;