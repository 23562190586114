import {neverland as $, render, html, useState, useEffect} from 'neverland';

const TextareaField = $(function(f, fields, doc, updateDoc) {
	
	function updateField(ev) {
		updateDoc(f, ev.target.value);
	}

	return html`
		<textarea
			class="field-${f}"
			oninput="${updateField}"
		>${doc.curr[f]}</textarea>
		<label>${fields[f].title}</label>
	`;

	// return html`
	// 	<input
	// 		class="field-${f}"
	// 		type="text"
	// 		value="${doc.curr[f]}"
	// 		oninput="${updateField}"></input>
	// 	<label>${fields[f].title}</label>
	// `;

});

export default TextareaField;